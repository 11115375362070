import React from 'react'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, CreditCard } from '@mui/icons-material'
import { Box, BoxContainer, FlexRow } from '../styledComponents';

export default function CardOption(props) {
    const { label, selected, onClick, } = props;

    return (<Box onClick={onClick} style={{height: '3em', margin: "10px", verticalAlign: "middle"}}>
        <BoxContainer>
            <FlexRow style={{margin: "auto 0"}}>
                {!!selected && <CheckBoxRounded/>}
                {!selected && <CheckBoxOutlineBlankRounded />}
                <CreditCard />
                <span>{label}</span> 
            </FlexRow>
        </BoxContainer>
    </Box>
    )
}