import React, { useCallback, useContext, useState, useEffect } from 'react'
import Context from '../../../setup/context'
import { ErrorAlert } from '../../../styles/alerts'
import Loader from '../../_common/loader'
import Modal from '../../_common/modal'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonRed } from '../../../styles/buttons'
import { uploadFile } from '../../../setup/restService'

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#96c823';
    }
    if (props.isDragReject) {
        return '#cf2432';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const errCodes = {
    'file-invalid-type': 'is not an accepted file type. ',
    'file-too-large': 'is too large to upload. ',
    'too-many-files': 'Too many files attempted. '
}

const RootContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #FAFAFA;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width: 400px;
`;

const DivRow = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
`

const FileRow = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  color: black;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
  h4 {
    font-family: ProximaBold;
  }
  p {
    font-family: Proxima;
    text-align: center;
  }
  button {
    margin-top: 8px;
  }
  height: 350px;
  width: 500px;
`

const FilesDisplayContainer = styled.div`
  height: 125px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadingContainer = styled.div`
  position: absolute;
  height: 325px;
  width: 500px;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Pointer = styled.div`
  cursor: pointer;
`

const Relative = styled.div`
  postion: relative;
`

export default function DocumentLinkModal(props) {
    const {
        open,
        hide,
        area,
        accept, 
        allowedMessage,
        maxFiles
    } = props

    const { attachments, setAttachments, deleteAttachments } = useContext(Context)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reject, setReject] = useState(false)
   
    const onDrop = useCallback((dropped, rejected) => {
        let errStr = ''
        if ((attachments.length + dropped.length > maxFiles) || (attachments.length + rejected.length > 5)) {
            errStr += errCodes['too-many-files']
        } else {
            if (rejected?.length > 0) {
                for(const r of rejected) {
                    const name = r.file.name
                    const code = r.errors[0].code
                    errStr += `${name} ${errCodes[code]}`
                }
            }
            if (dropped.length && (attachments.length + dropped.length <= maxFiles)) {
                addFile(dropped)
            } 
        }
        if(errStr.length > 0) {
            setError(errStr)
        }
    }, [attachments])

    function addFile(dropped) {
        setLoading(true)
        const promises = [];
        for (let i = 0; i < dropped.length; i++) {
            promises.push(uploadFile(dropped[i], area))
        }

        Promise.all(promises)
            .then(res => {
                const jsonPromises = []
                const spliceIndexes = []
                for (let i = 0; i < dropped.length; i++) {
                    if(res[i].status === 400) {
                        spliceIndexes.unshift(i)
                        setError('Unsupported file upload attempted.')
                    } else if (res[i].status !== 201) {
                        spliceIndexes.unshift(i)
                        setError('There was an error uploading, please try again.')
                    } else {
                        jsonPromises.push(res[i].json())
                    }
                }
                if(!res.some(r => r.status !== 201)) {
                    setError(false)
                }
                for(const i of spliceIndexes) {
                    dropped.splice(i, 1)
                }
                Promise.all(jsonPromises).then(r => {
                    const addedFiles = [];
                    for (let i = 0; i < dropped.length; i++) {
                        addedFiles.push({ fileName: dropped[i].name, fileId: r[i] })
                    }
                    setAttachments([...attachments, ...addedFiles])
                    setLoading(false)
                }).catch(() => {
                    setError('There was an error uploading, please try again.')    
                    setLoading(false)
                })
            })
            .catch(() => {
                setError('There was an error uploading, please try again.')    
                setLoading(false)
            })
    }
    
    function removeFile(fileId) {
        deleteAttachments([{fileId}])
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept,
        maxFiles,
        maxSize: 10000000
    })

    const filesDisplay = attachments.map(f => {
        return (
            <FileRow onClick={e => e.stopPropagation()} key={f.fileId}>
                <FontAwesomeIcon icon='file-upload' color="black" />
                <div>{f.fileName}</div>
                <Pointer>
                    <FontAwesomeIcon onClick={() => removeFile(f.fileId)} icon='minus-circle' color="#cf2432" />
                </Pointer>
            </FileRow>
        )
    })

    function onDragEnter(e) {
        const dropLength = e.dataTransfer.items.length
        if (attachments.length + dropLength > maxFiles) {
            setReject(true)
        }
    }

    useEffect(() => {
        if (!isDragActive) {
            setReject(false)
        }
    }, [isDragActive])

    return (
        <Modal open={open} onClose={hide} contentStyle={{ maxWidth: 500, borderRadius: 3 }}>
            <Container>
                {loading && (
                    <Relative>
                        <LoadingContainer>
                            <Loader />
                        </LoadingContainer>
                    </Relative>
                )}
                <h4>Attach Files</h4>
                <RootContainer
                    {...getRootProps({
                        isDragActive,
                        isDragReject: reject || isDragReject,
                        isDragAccept: reject === true ? false : isDragAccept,
                        onDragEnter
                    })}
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag and drop files here, or click to select files</p>
                    }
                    <FilesDisplayContainer>
                        {error && 
                            <ErrorAlert 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setError(false)
                                }} 
                                style={{ 
                                    position: 'absolute', 
                                    width: '80%',
                                    cursor: 'pointer'
                                }}
                            >
                                {error} X
                            </ErrorAlert>
                        }
                        {filesDisplay}
                    </FilesDisplayContainer>
                    <div>{allowedMessage}</div>
                </RootContainer>
                <DivRow>
                    <ButtonRed onClick={hide}>Done</ButtonRed>
                </DivRow>
            </Container>
        </Modal>
    )
}