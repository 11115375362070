import { useState } from 'react'

export default function useObjState(initState) {
    const [state, setState] = useState(initState)

    function setStateItem(field, value) {
        setState({ ...state, [field]: value })
    }

    return [state, setState, setStateItem]
}