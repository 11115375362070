const PALETTE = {
    primary: { //Red
        main: '#b51f2b', //Airline Red
        dark1: '#950f23', //Button Gradient 1
        dark2: '#db1633', //Button Gradient 2
    },
    secondary: { //Blue
        main: '#246696',
        contrastText: 'white'
    },
    grays: {
        light: '#f2f3f4',
        medium: '#bababa',
        dark: '#555555'
    }
}

//Place reusable bits of data here that can be referred to inline in styled`` sections, eg, ${props => props.theme.mainColorHover}
// The props will be accessible by any styled component located within a ThemeProvider wrapper
export const airlineRedTheme = {
    mainColor: '#DB1633',
    mainColorBlend: '#950f23',
    mainColorHover: '#B51029',
    buttonForegroundColor: 'white',
    buttonShadow: 'black',
    altButtonColor: '#003978',
    altButtonColorBlend: '#001d3d',
    backgroundImageUrl: 'https://airlinemedia.airlinehyd.com/Graphics/images/Home/CategoryBackground.jpg',
    sectionBackgroundImageUrl: 'https://airlinemedia.airlinehyd.com/Static_pages/Brands/brands-bg.jpg',
    fancyFontName: 'Proxima',
    fancyFontNameBold: 'ProximaBold',
    GeneralFontName: 'Verdana',
    headingFont: 'Verdana',
    linkColor: '#328EFC',
    textFont: '',
    backgroundColor: '#f2f3f4',
    divisionColor: '#dcdcdc',
    divisionColorBlendDarker: '#c4c4c4',
    divisionColorBlendLighter: '#f5f5f5',
    accents: {
        primary: PALETTE.primary.main
    },
    buttons: {
        primary: {
            backgroundGradientLeft: PALETTE.primary.dark1,
            backgroundGradientRight: PALETTE.primary.dark2,
            textColor: PALETTE.grays.light,
        },
        secondary: {
            backgroundColor: PALETTE.secondary.main,
            textColor: PALETTE.grays.light
        }
    },
    backgrounds: {
        blue: {
            main: PALETTE.secondary.main,
            contrastText: PALETTE.grays.light
        }
    }
}

