import React from 'react'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import { OptionTable } from '../styledComponents'
import { moneyFormatter } from 'pageComponents/_common/util/formatters'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { format as dateFormat } from 'date-fns'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'

export default function ShippingOptions() {
    const {
        checkoutEditableFields,
        setCheckoutEditableFields,
        shippingOptions,
        shippingDataIsLoading
    } = useCheckout2();

    return (<>
        {shippingOptions &&
            <OptionTable>
                <tbody>
                    {shippingOptions.map((opt, index) =>
                        <tr key={`shp_${index}`} onClick={() => setCheckoutEditableFields({ ...checkoutEditableFields, ShippingChoices_SelectedShippingCarrier: opt.CarrierName})}>
                            <td>{opt.DisplayName}<br />
								<span style={{paddingLeft: "10px"}}>Est. Delivery {opt.EstimatedDeliveryDate ? dateFormat(new Date(opt.EstimatedDeliveryDate), DATE_FORMAT_DISPLAY) : <i>not available</i>}</span>
								{opt.EstimatedDeliveryDate === null && opt.EstimatedShipDate !== null ? <><br/><span style={{paddingLeft: "10px"}}>Est. Ship Date {dateFormat(new Date(opt.EstimatedShipDate), DATE_FORMAT_DISPLAY)}</span></> : <></>}
							</td>
						    <td style={{ textAlign: "right" }}>{ opt.Price !== null ? moneyFormatter.format(opt.Price) : <i>$ TBD</i>}</td>
                            <td style={{ width: "40px" }}>{opt.CarrierName === checkoutEditableFields.ShippingChoices_SelectedShippingCarrier ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}</td>
                        </tr>
                    )}
                </tbody>
            </OptionTable>}
        {!shippingOptions && !shippingDataIsLoading && 
            <span style={{ textAlign: "center", fontStyle: "italic", width: "100%" }}>Click the Get Shipping Rates button to get shipping rates.</span>
        }
    </>)
}

export function PreviouslySelectedShippingOption(props) {
    const { carrierName, price } = props;
    return (<OptionTable>
        <tbody>
            <tr>
                <td>{carrierName}</td>
                <td style={{ textAlign: "right" }}>{moneyFormatter.format(price)}</td>
                <td style={{ width: "40px" }}><CheckBoxRounded /></td>
            </tr>
        </tbody>
    </OptionTable>);

}
