import { useLazyQuery, useQuery } from '@apollo/client'
import { format as dateFormat } from 'date-fns'
import OrderDetailItem from 'pageComponents/Account/ordersPages/orderDetailItem'
import ExportButtons from 'pageComponents/Account/uiComponents/exportButtons'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router'
import { GET_ORDER_DETAIL_ITEM_DETAIL } from 'setup/gqlQueries/gqlItemQueries'
import { GET_ITEM_AVAILABILITY, GET_ITEM_PRICE, GET_ANONYMOUS_ORDERS_DETAIL, GET_ANONYMOUS_INVOICE } from 'setup/providerGQL'
import styled from 'styled-components'
import AirlineLogo from '../../imgs/airline/airline_logo_vector.svg'

import { Container, DivHeader, DivOrderInfo, DivOrderInfoContainer } from '../../pageComponents/Account/orderQuoteShared'
import { ButtonRed } from 'styles/buttons'
import {StickyModal} from 'pageComponents/_common/modal'
import { useLocation } from 'react-router'
import { ShowNoteAlert } from 'styles/alerts'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'

const style = {
    centered: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    modal: {
        width: "80vw",
        height: "80vh",
        background: "white",
        right: "10px",
        color: "black",
        zIndex: "999",
        borderRadius: "16px",
        boxShadow: "0 5px 20px 0 rgba(0, 0, 0, .004)",
    },

    form: {
        display: "flex",
        justifyContent: "center"
    },

    input: {
        width: "130px",
        overflow: "hidden",
        display: "inline-grid"
    }
}

export default function OrderDetailModal(props) {
    // TODO: remove defaults
    const email = useInput("");
    const orderNumber = useInput("");
    const [errorMessage, setErrorMessage] = useState(<p></p>);
    const [isValid, setIsValid] = useState(false)
    const navigate = useNavigate()
    
    const {
        open,
        hide
    } = props

    useEffect(() => {
        setIsValid(false)
    }, [email.value, orderNumber.value])

    function Submit() {
        if (orderNumber.value === "" && email.value === "") {
            setErrorMessage(<p>please provide a email address and a Reference or Order Number</p>)
            return;
        }
        else if (orderNumber.value === "") {
            setErrorMessage(<p>please provide a Reference or Order Number</p>)
            return;
        }
        else if (email.value === "") {
            setErrorMessage(<p>please provide a valid email address</p>)
            return;
        } else {
            setErrorMessage(<p></p>)
            setIsValid(true)
        }
    }

    return <>
        <StickyModal
            open={open}
            onClose={() => hide? hide(false): navigate("../../")}
            contentStyle={{width: "99%", minWidth: "225px", maxWidth: "600px", maxHeight: "99%", minHeight: "225px" ,overflow: "auto"}}
        >
            <Container>
                <h6>Have Questions? <a href="mailto: websupport@airlinehyd.com">Contact Web Sales</a></h6>
                <h4 style={{ textAlign: "center" }}>Order Search</h4>
                <div style={style.form}>
                    <form>
                        <label style={style.input}>Email</label>
                        <input {...email}></input><br></br>
                        <label style={style.input}>Order Number</label>
                        <input {...orderNumber}></input>
                    </form>
                    <ButtonRed style={{ marginLeft: "25px", height: "35px", display: "inline-grid" }} onClick={Submit}>Search</ButtonRed>
                </div>
                <div style={{ textAlign: "center" }}>{errorMessage}</div>
                {isValid && <InnerModal orderId={orderNumber.value.replace(/\s/g, "")} email={email.value.replace(/\s/g, "")}></InnerModal>}
            </Container>
        </StickyModal>
    </>
}


function useInput(defaultValue) {
    const [value, setValue] = useState(defaultValue);
    function onChange(e) {
        if (e && e.target) {
            setValue(e.target.value);
        } else {
            setValue(e)
        }
    }
    return {
        value,
        onChange,
    };
}


function InnerModal({ orderId, email }) {
    const [filter, setFilter] = useState('')
    const [orderDetails, setOrderDetails] = useState(null)
    const [itemsDetails, setItemsDetails] = useState([])
    const [itemAvailabilities, setItemAvailability] = useState([])
    const [currentPrices, setCurrentPrices] = useState([])
    const [showShowAddedToCartModal, setShowAddedToCartModal] = useState(false)
    const [invoiceData, setInvoiceData] = useState({})
    const navigate = useNavigate()
    const [loadingStatus, setLoadingStatus] = useState("Loading Order Data")

    const [getOrderDetailItemDetail, { loading: isItemsDetailsLoading }] = useLazyQuery(GET_ORDER_DETAIL_ITEM_DETAIL, {
        fetchPolicy: 'no-cache',
        onCompleted: result => {
            setItemsDetails(result.itemDetailsBatch)
        }
    })
    const [getItemAvailability, { loading: isItemAvailabilityLoading }] = useLazyQuery(GET_ITEM_AVAILABILITY, {
        fetchPolicy: 'no-cache',
        onCompleted: result => {
            setItemAvailability(result.itemAvailability)
        }
    })
    const [getCurrentPrices, { loading: isItemPriceLoading }] = useLazyQuery(GET_ITEM_PRICE, {
        fetchPolicy: 'no-cache',
        onCompleted: result => {
            setCurrentPrices(result.getItemPrices)
        }
    })

    const [getInvoice, { loading: isInvoiceLoading }] = useLazyQuery(GET_ANONYMOUS_INVOICE, {
        fetchPolicy: 'no-cache',
        onCompleted: result => {
            setInvoiceData(result.anonymousInvoiceDetail)
        }
    })

    const { loading: isOrderDetailsLoading } = useQuery(GET_ANONYMOUS_ORDERS_DETAIL, {
        fetchPolicy: 'no-cache',
        variables: { orderNumber: String(orderId), email: email },
        onCompleted: result => {
            setLoadingStatus("Loading Order Data")
            if (result.anonymousOrderDetails) {
                setOrderDetails(result.anonymousOrderDetails)
                const invMastUids = (result.anonymousOrderDetails.lineItems?.map(item => item.invMastUid) || [])
                getOrderDetailItemDetail({
                    variables: {
                        invMastUids: invMastUids
                    }
                })
                getItemAvailability({
                    variables: {
                        invMastUids: invMastUids
                    }
                })
                getCurrentPrices({
                    variables: {
                        items: invMastUids.map(invMastUid => {
                            return {
                                invMastUid: invMastUid,
                                quantity: 1
                            }
                        })
                    }
                })
                getInvoice({
                    variables: { orderNumber: String(orderId), email: email }
                })
            } else {
                setLoadingStatus(`Order #${orderId} is not connected to ${email}`);
            }
        }
    })

    const pdf_export = () => {

        const data = {
            orderId: orderDetails.orderNumber,
            orderDate: orderDetails.orderDate,
            PoNo: orderDetails.poNo,
            BillToCustomerIdP21: orderDetails.billToCustomerIdP21,
            BillToName: orderDetails.billToName,
            BillToCompanyName: orderDetails.billToCompanyName,
            BillToAddress1: orderDetails.billToAddress1,
            BillToAddress2: orderDetails.billToAddress2,
            BillToAddress3: orderDetails.billToAddress3,
            BillToCity: orderDetails.billToCity,
            BillToState: orderDetails.billToState,
            BillToZip: orderDetails.billToZip,
            BillToCountry: orderDetails.billToCountry,
            BillToPhone: orderDetails.billToPhone,
            ShipToName: orderDetails.shipToName,
            ShipToAddress1: orderDetails.shipToAddress1,
            ShipToAddress2: orderDetails.shipToAddress2,
            ShipToAddress3: orderDetails.shipToAddress3,
            ShipToCity: orderDetails.shipToCity,
            ShipToState: orderDetails.shipToState,
            ShipToZip: orderDetails.shipToZip,
            ShipToCountry: orderDetails.shipToCountry,
            ShipToPhone: orderDetails.shipToPhone,
            DeliveryInstructions: orderDetails.deliveryInstructions,
            Notes: orderDetails.orderNotes,
            LineItems: orderDetails.lineItems,
            Tax: orderDetails.tax,
            Total: orderDetails.total,
            termsConditionsUri: orderDetails.termsConditionsUri
        }
        navigate(`/anonymous/orderPDF`, { state: data })
    }

    const invoice_export = (current) => {
        navigate(`/anonymous/invoicePDF`, { state: current })
    }

    function getExportData() {
        const filteredListItems = (!orderDetails.lineItems || !orderDetails.lineItems.length)
            ? []
            : orderDetails.lineItems.filter(i => {
                if (!filter || !filter.length) return true

                const currentItemCode = i.itemCode.toLowerCase()
                const filterLower = filter.toLowerCase()

                return currentItemCode.indexOf(filterLower) > -1
            })

        return filteredListItems?.map((item) => {
            return {
                ...item,
                ...orderDetails,
                trackingNo: item.trackingNumbers?.map(t => t.trackingNumber).join(' - ')
            }
        })
    }

    const ItemDetails = () => {
        const filteredListItems = (!orderDetails.lineItems || !orderDetails.lineItems.length)
            ? []
            : orderDetails.lineItems.filter(i => {
                if (!filter || !filter.length) return true

                const currentItemCode = i.itemCode.toLowerCase()
                const filterLower = filter.toLowerCase()

                return currentItemCode.indexOf(filterLower) > -1
            })

        const itemNodes = filteredListItems?.map((item) => {
            const itemDetails = itemsDetails.find(detail => detail.invMastUid === item.invMastUid)
            const itemPrice = currentPrices?.find(price => price.invMastUid === item.invMastUid)
            item.extended = itemDetails
            item.uom = itemPrice?.unitOfMeasure
            return (
                <OrderDetailItem
                    key={item.lineNumber}
                    item={item}
                    itemDetails={itemDetails}
                    priceInfo={itemPrice}
                    hidePurchaseButtons={true}
                />
            )
        })

        if (!itemNodes || itemNodes.length === 0) {
            return <p>No items found matching search.</p>
        } else {
            return itemNodes
        }
    }

    const exportColumns = [
        {
            Header: "Item Code",
            accessor: "itemCode"
        }, {
            Header: "AHC#",
            accessor: "invMastUid"
        }, {
            Header: "Customer Part #",
            accessor: "customerPartNumber"
        }, {
            Header: "Qty Ordered",
            accessor: "quantityOrdered"
        }, {
            Header: "Unit Of Measure",
            accessor: "unitOfMeasure"
        }, {
            Header: "Qty Recieved",
            accessor: "quantityInvoiced"
        }, {
            Header: "Qty Allocated",
            accessor: "quantityAllocated"
        }, {
            Header: "Cancelled",
            accessor: "isCancelled"
        }, {
            Header: "Unit Price",
            accessor: "unitPrice"
        }, {
            Header: "Total Price",
            accessor: "totalPrice"
        }, {
            Header: "Order Date",
            accessor: "orderDate"
        }, {
            Header: "Promise Date",
            accessor: "promiseDateOrTBD"
        }, {
            Header: "Order Number",
            accessor: "orderNumber"
        }, {
            Header: "P.O. Number",
            accessor: "poNo"
        }, {
            Header: "Tracking #",
            accessor: "trackingNo"
        }, {
            Header: "Status",
            accessor: "status"
        }, {
            Header: "Packing Basis",
            accessor: "packingBasis"
        }, {
            Header: "Order Total",
            accessor: "total"
        }, {
            Header: "Ship To Name",
            accessor: "shipToName"
        }, {
            Header: "Ship To Address 1",
            accessor: "shipToAddress1"
        }, {
            Header: "Ship To Address 2",
            accessor: "shipToAddress2"
        }, {
            Header: "Ship To Address 3",
            accessor: "shipToAddress3"
        }, {
            Header: "Ship To City",
            accessor: "shipToCity"
        }, {
            Header: "Ship To State",
            accessor: "shipToState"
        }, {
            Header: "Ship To Zip",
            accessor: "shipToZip"
        },
    ]

    if (!orderDetails || itemsDetails.length === 0 || isOrderDetailsLoading || isItemAvailabilityLoading || isItemPriceLoading || isItemsDetailsLoading) {
        return (
            <p>{loadingStatus}</p>
        )
    } else {
        return (
            <div>
                {(invoiceData) && invoiceData.map && invoiceData.map((curr) => { return <><span style={{ color: "blue", marginRight: "5px", textDecoration: "underline", cursor: 'pointer' }} onClick={() => invoice_export(curr)}>Invoice# {curr.invoiceNumber}</span><br /></> })}
                <DivHeader>
                    <h4>{orderDetails.total > 0 ? 'Order #' : 'RMA #'}{orderId}</h4>
                    <ExportButtons hidePDF={false} data={getExportData()} columns={exportColumns} title={`airline_order_${orderId}.pdf`} pdfCallback={pdf_export} />
                </DivHeader>
                <DivOrderInfoContainer>
                    <DivOrderInfo>
                        <p>Order Date: {orderDetails.orderDate ? dateFormat(new Date(orderDetails.orderDate), DATE_FORMAT_DISPLAY) : '--'}</p>
                        <p>Promise Date: {orderDetails.promiseDateOrTBD}</p>
                        <p>Order Number: {orderId}</p>
                        <p>P.O. Number: {orderDetails.poNo}</p>
                        <p>Status: {orderDetails.status}</p>
                        <p>Packing Basis: {orderDetails.packingBasis}</p>
                        <p>Order Total: <NumberFormat value={orderDetails.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></p>
                    </DivOrderInfo>
                    <DivOrderInfo>
                        <p>Ship-to-Address:</p>
                        <p>{orderDetails.shipToName}</p>
                        <p>{orderDetails.shipToAddress1}</p>
                        {orderDetails.shipToAddress2 && <p>{orderDetails.shipToAddress2}</p>}
                        {orderDetails.shipToAddress3 && <p>{orderDetails.shipToAddress3}</p>}
                        <p>{orderDetails.shipToCity}, {orderDetails.shipToState} {orderDetails.shipToZip}</p>
                    </DivOrderInfo>
                </DivOrderInfoContainer>
                {orderDetails.orderNotes && orderDetails.orderNotes.length > 0 &&
                    <>
                        {orderDetails.orderNotes.map((note, idx) =>
                            <ShowNoteAlert key={idx} message={note} />
                        )}
                    </>
                }
                <div>
                    <ItemDetails />
                </div>
            </div>
        )
    }
}

export function OrderPDF() {
    const {
        orderDate,
        orderId,
        PoNo,
        BillToCustomerIdP21,
        BillToName,
        BillToCompanyName,
        BillToAddress1,
        BillToAddress2,
        BillToAddress3,
        BillToCity,
        BillToState,
        BillToZip,
        BillToCountry,
        BillToPhone,
        ShipToName,
        ShipToAddress1,
        ShipToAddress2,
        ShipToAddress3,
        ShipToCity,
        ShipToState,
        ShipToZip,
        ShipToCountry,
        ShipToPhone,
        DeliveryInstructions,
        Notes,
        LineItems,
        Total,
        Tax,
    } = useLocation().state;

    function getMedia(item) {
        let media = item.extended.itemMedia.find(elem => elem.itemMediaType === "THUMB")

        if (!media) {
            media = item.extended.itemMedia.find(elem => elem.itemMediaType === "ORIGINAL")
        }

        try {
            return ["https://" + media.path, media.altText]
        }
        catch (e) {
            return ["https://airlinemedia.airlinehyd.com/Item_Images/no-image.png", "No Image"]
        }
    }

    const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })

    return <>

        <div style={{ fontFamily: "Arial", lineHeight: "1.5em", width: "1063px" }}>
            <table style={{ width: "100%", height: "100%" }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: "center" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "bottom" }}>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>
                                                            <a style={{ textDecoration: "none" }} target="_blank" href={process.env.REACT_APP_SITE_URL}>
                                                                <img alt="Airline Logo" title="Airline Hydraulics" src="https://airlinemedia.airlinehyd.com/Email/Airline_tagline_p21.png" />
                                                            </a>
                                                        </td>
                                                        <td style={{ verticalAlign: "bottom", textAlign: "center", width: "33%" }}>
                                                            <p style={{ margin: "0", fontSize: "15pt" }}><a target="_blank" href={process.env.REACT_APP_SITE_URL}>www.airlinehyd.com</a></p>
                                                            <span style={{ marginTop: "0", marginBottom: "0", marginLeft: "4px", marginRight: "0", fontSize: "15pt", fontWeight: "bold", color: "#f20000" }}>1-800-999-7378</span>
                                                        </td>
                                                        <td style={{ verticalAlign: "top", textAlign: "right", width: "33%" }}>
                                                            <p style={{ margin: "0", textAlign: "right", fontSize: "18pt", color: "#000000" }}>
                                                                <strong>Order Summary</strong>
                                                            </p>
                                                            <p style={{ margin: "0", textAlign: "right", fontSize: "13pt", color: "#656565" }}>
                                                                Order # {orderId}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: "top", textAlign: "left" }}>
                            <table style={{ width: "100%", borderTop: "3px solid #707070" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "50%" }}>
                                            <b>Order Date: </b> {new Date(orderDate).toLocaleDateString()}<br />
                                            {PoNo && <><b>Purchase Order #:</b> {PoNo}<br></br></>}
                                            {BillToCustomerIdP21 && BillToCustomerIdP21 !== 192095 && <><b>Billing Information: Acct#  {BillToCustomerIdP21} </b><br /></>}
                                            {BillToName}<br />
                                            {BillToCompanyName}<br />
                                            {BillToAddress1}<br />
                                            {BillToAddress2 && <>{BillToAddress2}<br /></>}
                                            {BillToAddress3 && <>{BillToAddress3}<br /></>}
                                            {BillToCity}, {BillToState} {BillToZip}, {BillToCountry && <>{BillToCountry}</>}<br />
                                            {BillToPhone}
                                        </td>
                                        <td>
                                            <b>Shipping Information:</b><br />
                                            {ShipToName}<br />
                                            {ShipToAddress1}<br />
                                            {ShipToAddress2 && <>{ShipToAddress2}<br /></>}
                                            {ShipToAddress3 && <>{ShipToAddress3}<br /></>}
                                            {ShipToCity}, {ShipToState} {ShipToZip}, {ShipToCountry && <>{ShipToCountry}</>}<br />
                                            {ShipToPhone}<br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    {DeliveryInstructions &&
                        <tr>
                            <td>
                                <p style={{ paddingLeft: "10px", marginTop: "6px", marginBottom: "5px", marginLeft: "0", marginRight: "0", fontSize: "13pt", color: "#000000" }}><b>Shipment Notes: </b> {<span dangerouslySetInnerHTML={{ __html: DeliveryInstructions }} />}
                                </p>
                            </td>
                        </tr>
                    }
                    {Notes && Notes.length > 0 && <>
                        {Notes.map((note, idx) =>
                            <tr>
                                <td>
                                    <p style={{ paddingLeft: "10px", marginTop: "6px", marginBottom: "5px", marginLeft: "0", marginRight: "0", fontSize: "13pt", color: "#000000" }}><b>Order Notes: </b> {<span dangerouslySetInnerHTML={{ __html: note }} />}</p>
                                </td>
                            </tr>)
                        }
                    </>
                    }
                    <tr>
                        <td style={{ marginTop: "10px" }}>
                            <table style={{ borderCollapse: "collapse", borderWidth: "1px", borderStyle: "solid", borderColor: "#707070", width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
                                            <b >Order Details</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "10px" }}>
                                            <table className="print-friendly" style={{ fontSize: "11pt", width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: "left", verticalAlign: "middle", width: "7%" }}>Line #</th>
                                                        <th style={{ textAlign: "center", verticalAlign: "middle", width: "28%" }}>Item</th>
                                                        <th style={{ textAlign: "center", verticalAlign: "middle", width: "10%" }}>Qty</th>
                                                        <th style={{ textAlign: "center", verticalAlign: "middle", width: "25%" }}>Expected Ship Date</th>
                                                        <th style={{ textAlign: "right", verticalAlign: "middle", width: "15%" }}>Price</th>
                                                        <th style={{ textAlign: "right", verticalAlign: "middle", width: "15%", wordWrap: "break-word" }}>Extended Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6" style={{ marginTop: "5px", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd" }}></td>
                                                    </tr>
                                                    {LineItems && LineItems.map((detail) => {
                                                        const mediaInfo = getMedia(detail);
                                                        return <><tr>
                                                            <td>
                                                                {detail.lineNumber}
                                                            </td>
                                                            <td style={{ paddingBottom: "10px", textAlign: "center" }}>
                                                                <><img width="75" border="0" className="Image" src={mediaInfo[0]} alt={mediaInfo[1]} title={mediaInfo[1]} /><br /></>
                                                                {/* href="@(new Uri(Model.WebBaseUrl, detail.Url).ToString())" target="_blank" this was an a tag, but there is no link in the item data*/}
                                                                <p style={{ color: "#136db4", textDecoration: "none" }}>
                                                                    {!detail.customerPartNumber ? detail.itemCode : detail.customerPartNumber}
                                                                </p>
                                                                <p>
                                                                    AHC {detail.invMastUid}
                                                                </p>
                                                            </td>
                                                            <td style={{ textAlign: "right", wordWrap: "break-word" }}>{detail.quantityOrdered}</td>
                                                            <td style={{ textAlign: "center", wordWrap: "break-word" }}>
                                                                {detail.promiseDateOrTBD ? detail.PromiseDateOrTBD : (detail.AroDays ? "Available " + detail.AroDays + " Days ARO" : "")}
                                                            </td>

                                                            <td style={{ textAlign: "right", wordWrap: "break-word" }}>{moneyFormatter.format((100 * detail.unitPrice) / 100)} {detail.uom}</td>
                                                            <td style={{ textAlign: "right", wordWrap: "break-word" }}>{moneyFormatter.format(detail.totalPrice)}</td>
                                                        </tr>
                                                            <tr>
                                                                <td colSpan="6">
                                                                    {detail.extended && detail.extended.isNcnr &&
                                                                        <>**NCNR Item**<br /></>
                                                                    }
                                                                      {detail.extended && detail.extended.isDiscontinued &&
                                                                        <>**Discontinued Item**<br /></>
                                                                    }
                                                                    {detail.itemNotes.length !== 0 && detail.itemNotes.map((n) => { return <p><b>Notes: </b> {n.note}<br /></p> })}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="6" style={{ marginBottom: "3px", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd" }}></td>
                                                            </tr>
                                                        </>
                                                    })}
                                                    <tr style={{ pageBreakAfter: "auto" }}>
                                                        <td colSpan="6" style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "right" }}>
                                                            <b>Tax: </b>{moneyFormatter.format(Tax)}<br />
                                                            <b>Total: </b>{moneyFormatter.format(Total)}<br />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: "top", textAlign: "center" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            {/* {LineItems.some((d) => d.Extended.IsNcnr) && 
                                    <p style={{marginTop: "5px", fontSize: "12pt", color: "#DB1633", textAlign: "center"}}>
                                        Your order contains one or more NCNR items. These items are non-cancelable and non-returnable.
                                    </p>
                                } */}
                                            <p style={{ marginTop: "5px", fontSize: "12pt", color: "#DB1633", textAlign: "center" }}>
                                                All prices exclude any applicable taxes, tariffs or duties. Prices are subject to a line item tariff charge should there be any additional duties or restrictions imposed upon our vendor's products as a result of pending government actions or the current investigation under Section 232 of the Trade Expansion Act of 1962.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center" }}></td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: "top", textAlign: "center" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <p style={{ marginTop: "5px", marginBottom: "6px", marginLeft: "0", marginRight: "0", fontSize: "16pt", color: "#656565", lineHeight: "17px" }}>
                                                <strong>ISO 9001:2015 Certified</strong>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <p style={{ marginTop: "5px", marginBottom: "6px", marginLeft: "0", marginRight: "0", fontSize: "13pt", color: "#656565", lineHeight: "17px" }}>
                                                Airline Hydraulics Corporation is committed to maintaining a high level of customer satisfaction. We will achieve this goal through on-time delivery of high quality products and services, while promoting a continuous improvement environment supported by our Quality Program.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <a style={{ fontSize: "13pt", textDecoration: "none", color: "#136db4" }} target="_blank" href="https://www.linkedin.com/company/airline-hydraulics-corporation" rel="noreferrer">Find us on LinkedIn</a>
                                            <span style={{ fontSize: "12pt" }}> | </span>
                                            <a style={{ fontSize: "13pt", textDecoration: "none", color: "#136db4" }} target="_blank" href="https://www.twitter.com/airlinehyd" rel="noreferrer">Follow us on Twitter</a>
                                            <span style={{ fontSize: "12pt" }}> | </span>
                                            <a style={{ fontSize: "13pt", textDecoration: "none", color: "#136db4" }} target="_blank" href="https://airlinemedia.airlinehyd.com/Literature/Airline_Terms_and_Conditions.pdf" rel="noreferrer">Terms &amp; Conditions</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <p style={{ fontSize: "11pt", color: "#888888" }}>Airline Hydraulics Corporate Headquarters: 3557 Progress Drive, Bensalem, PA 19020</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
}

export function OrderInvoice() {
    const isNil = val => val == null

    // these are saved to location so this page doesn't have to access gql
    const {
        invoiceNumber,
        orderDate,
        poNo,
        status,
        shipToName,
        shipToAddress1,
        shipToAddress2,
        shipToAddress3,
        shipToCity,
        shipToState,
        shipToZip,
        deliveryInstructions,
        billingName,
        billingAddress1,
        billingAddress2,
        billingAddress3,
        billingCity,
        billingState,
        billingZip,
        lineItems,
        terms,
        taker,
        orderNumber,
        invoiceNotes: notes,
        invoiceDate,
        netDueDate,
        discDueDate,
        discountAmount,
        orderedBy,
        subTotal,
        totalTax,
        amountDue,
        freightAmount,
        totalAmount
    } = useLocation().state

    const PageContainer = styled.div`
    	padding: 16px 32px;
    	box-shadow: 8px 8px 6px -6px lightgray;
    	border: 1px solid whitesmoke;
    `

    const DivOrderInfoContainer = styled.div`
        display: flex;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    `

    const DivNotesContainer = styled(DivOrderInfoContainer)`
        flex-direction: column;
    `
    const DivOrderInfo = styled.div`
    		width: 300px;
    		display: flex;
    		flex-direction: column; 
    		margin: 8px 0;
    		p {
    			margin: 0;
    			margin-left: 8px;  
    		}
    	`

    const DivHeader = styled.div`
    		display: flex;
    		align-items: center;
    		margin: 4px 0;
    		h4 {
    			margin: 0;
    		}
    		p {
    			cursor: pointer;
    			color: grey;
    			font-size: 14px;
    			margin: 0 0 0 auto;
    		}
    	`

    const DivItemDetail = styled.div`
    		display: flex;
    		min-height: 48px;
    		padding: 8px;
    		border-bottom: 1px solid whitesmoke;
    	`

    const DivItemDetailHeader = styled(DivItemDetail)`
    		font-weight: 700;
    		border-bottom: 1px solid grey;
    		padding: 0;
    	`

    const DivItemDetailCell = styled.div`
    		flex: display;
    		flex-direction: column;
    		width: ${props => props.width};
    		align-self: center;
    		text-align: ${props => isNil(props.align) ? 'left' : props.align};
    	`

    const P1 = styled.p`
    		font-weight: bold;
    		margin: 0px 4px 0px auto;
    	`

    const P2 = styled.p`
    		margin: 0 0 0 8px;
    		font-size: 14px;
    		color: darkgrey;
    	`

    const A = styled.a`
    		margin: 0 0 0 24px;
    		font-size: 14px;
    	`

    const DivTracking = styled.div`
    		display: flex;
    		flex-direction: column;
    	`

    const Row = styled.div`
            display: flex;
        `

    const DivTotalContainer = styled.div`
    	width: 300px;
        display: flex;
        flex-direction: column; 
    	border-top: 1px solid black;
    	border-bottom: 1px solid black;
    	padding: 5px 12px;
        margin-right: 0;
        margin-left: auto;
        p {
            margin: 0;
            margin-left: 8px;  
        }
        span {
            margin: 0;
            margin-left: auto;
        }
    `

    const itemDetails = lineItems?.map(item => {
        return (
            <DivItemDetail key={item.invMastUid}>
                <DivItemDetailCell width='300px'>
                    <P1>{item.itemDescription}</P1>
                    <P2>Item Code: {item.itemCode}</P2>
                    <P2>AHC#: {item.invMastUid}</P2>
                    {item.customerPartNumber && <P2>Customer Part #: {item.customerPartNumber}</P2>}
                    {item.trackingNumbers?.map(tracking => {
                        return (
                            <DivTracking key={tracking.trackingNumber}>
                                <P2>{tracking.carrierName}: </P2>
                                <A href={tracking.trackingUrl} target='_blank' rel='noopener noreferrer'>{tracking.trackingNumber}</A>
                            </DivTracking>
                        )
                    })}
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    {item.quantityRequested}
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    {item.quantityShipped}
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    {item.quantityRequested - item.quantityShipped}
                </DivItemDetailCell>
                <DivItemDetailCell width='100px' align='right'>
                    <NumberFormat value={item.unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
                </DivItemDetailCell>
                <DivItemDetailCell width='100px' align='right'>
                    <NumberFormat value={item.itemTotalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
                </DivItemDetailCell>
            </DivItemDetail>
        )
    })

    return (
        <PageContainer>
            <DivHeader>
                <img src={AirlineLogo} height="40px" alt="Airline Hydraulics" />
                <h4 style={{ paddingLeft: '8px', paddingRight: '8px' }}>Invoice #{invoiceNumber}</h4>
            </DivHeader>
            <DivOrderInfoContainer>
                <DivOrderInfo>
                    <P1>Bill-to-Address:</P1>
                    <p>{billingName}</p>
                    <p>{billingAddress1}</p>
                    {!isNil(billingAddress2) && <p>{billingAddress2}</p>}
                    {!isNil(billingAddress3) && <p>{billingAddress3}</p>}
                    <p>{billingCity}, {billingState} {billingZip}</p>
                </DivOrderInfo>
                <DivOrderInfo>
                    <P1>Ship-to-Address:</P1>
                    <p>{shipToName}</p>
                    <p>{shipToAddress1}</p>
                    {!isNil(shipToAddress2) && <p>{shipToAddress2}</p>}
                    {!isNil(shipToAddress3) && <p>{shipToAddress3}</p>}
                    <p>{shipToCity}, {shipToState} {shipToZip}</p>
                </DivOrderInfo>
            </DivOrderInfoContainer>
            <DivOrderInfoContainer>
                <DivOrderInfo>
                    <Row><P1>Invoice Date: </P1><p>{isNil(invoiceDate) ? '--' : dateFormat(new Date(invoiceDate), DATE_FORMAT_DISPLAY)}</p></Row>
                    <Row><P1>Invoice Number: </P1><p>{invoiceNumber}</p></Row>
                    <Row><P1>P.O. Number: </P1><p>{poNo}</p></Row>
                    <Row><P1>Order Number: </P1><p>{orderNumber}</p></Row>
                </DivOrderInfo>
                <DivOrderInfo>
                    <Row><P1>Status: </P1><p>{status}</p></Row>
                    <Row><P1>Terms: </P1><p>{terms}</p></Row>
                    <Row><P1>Net Due Date: </P1><p>{isNil(netDueDate) ? '--' : dateFormat(new Date(netDueDate), DATE_FORMAT_DISPLAY)}</p></Row>
                    <Row><P1>Disc Due Date: </P1><p>{isNil(discDueDate) ? '--' : dateFormat(new Date(discDueDate), DATE_FORMAT_DISPLAY)}</p></Row>
                    <Row><P1>Discount Amount: </P1><p>{discountAmount}</p></Row>
                </DivOrderInfo>
                <DivOrderInfo>
                    <Row><P1>Order Date: </P1><p>{isNil(orderDate) ? '--' : dateFormat(new Date(orderDate), DATE_FORMAT_DISPLAY)}</p></Row>
                    <Row><P1>Ordered By: </P1><p>{orderedBy}</p></Row>
                    <Row><P1>Taker: </P1><p>{taker}</p></Row>
                </DivOrderInfo>
            </DivOrderInfoContainer>
            <DivOrderInfoContainer>
                <DivOrderInfo>
                    <P1>{deliveryInstructions && 'Delivery Instructions:'}</P1><p>{deliveryInstructions}</p>
                </DivOrderInfo>
            </DivOrderInfoContainer>
            {notes && notes.length > 0 && <DivNotesContainer>
                {notes.map((note, idx) =>
                    <ShowNoteAlert message={note} key={idx} />
                )}
            </DivNotesContainer>}
            <DivItemDetailHeader>
                <DivItemDetailCell width='300px'>
                    Item Information
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    Ordered
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    Shipped
                </DivItemDetailCell>
                <DivItemDetailCell width='120px' align='center'>
                    Remaining
                </DivItemDetailCell>
                <DivItemDetailCell width='100px' align='right'>
                    Unit Price
                </DivItemDetailCell>
                <DivItemDetailCell width='100px' align='right'>
                    Total Price
                </DivItemDetailCell>
            </DivItemDetailHeader>
            {itemDetails}
            <DivTotalContainer>
                <Row><P1>Subtotal: </P1><NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></Row>
                <Row><P1>Tax: </P1><NumberFormat value={totalTax} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></Row>
                {freightAmount > 0 && <Row><P1>Shipping: </P1><NumberFormat value={freightAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></Row>}
                <Row><P1>Total: </P1><NumberFormat value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></Row>
                <Row><P1>Amount Due: </P1><NumberFormat value={amountDue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale /></Row>
            </DivTotalContainer>
        </PageContainer>
    )
}