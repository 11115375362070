import React from 'react'
import styled from 'styled-components'

const Hide = styled.span`
  @media print {
    display: none;
  }
`

export default function HidePrint({ children }) {
  return (
    <Hide>
      {children}
    </Hide>
  )
}