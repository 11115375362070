import React, { useEffect } from 'react'
import { FlexCol, FlexRow, InlineButtonRed } from '../../styledComponents'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { contactSchema } from 'pageComponents/Checkout2/util/validationSchema'
import ContactInput from './ContactInput'
import ContactBox from './ContactBox'

export default function ContactViewEdit(props) {
    const {
        contact,
        contactUpdatedCallback,
        showContactEditor,
        setShowContactEditor
    } = props;

    const {  
        checkoutEditableFields, 
        setCheckoutEditableFields
    } = useCheckout2();

    useEffect(() => {
        //If the contact is valid, hide the editor
        contactSchema.validate(contact).catch(e => setShowContactEditor(true));
    }, []);

    function handleEdit() {
        setShowContactEditor(true);
    }

    function updateContact(cont) {
        console.log('updateContact', cont);

        //Let the parent know the contact has changed
        contactUpdatedCallback(cont);

        //Update the checkout2 state for sync to DB
        setCheckoutEditableFields({
            ...checkoutEditableFields,
            SelectedContact_P21Id: cont.P21Id,
            SelectedContact_FirstName: cont.FirstName,
            SelectedContact_LastName: cont.LastName,
            SelectedContact_Title: cont.Title,
            SelectedContact_Phone: cont.Phone,
            SelectedContact_PhoneExt: cont.PhoneExt,
            SelectedContact_Email: cont.Email,
        });
    }

    return (
        <FlexCol>
            <FlexRow>
                {showContactEditor ? (
                    <ContactInput 
                        contact={contact}
                        hide={() => setShowContactEditor(false)}
                        contactUpdatedCallback={updateContact}
                    />
                ) : (
                    <>
                        <ContactBox selectedContact={contact} />
                        <InlineButtonRed onClick={() => handleEdit()}>Edit...</InlineButtonRed>
                    </>
                )}
            </FlexRow>
        </FlexCol>
    )
}