import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    exportToExcel,
    getCsvFormattedData,
} from '../helpers/export'
import { CSVLink } from 'react-csv'

const ButtonExport = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border: 1px solid lightgrey;
	border-radius: 5px;
	margin: 10px 4px;
	&:hover {
		background-color: whitesmoke;
	}
`

const DivRow = styled.div`
	display: flex;
	align-items: center;
`

export default function ExportButtons({ data, columns, title, pdfOnly, hidePDF, pdfCallback, fileNameNoExt }) {
    const exportIgnoreColumns = ['filter', 'itemMedia']
    const handleExcelExport = () => {
        if (data?.length) {
            exportToExcel(data, columns, title, exportIgnoreColumns, `${fileNameNoExt || title}.xlsx`)
        }
    }

    return (
        <DivRow>
            {!hidePDF && (
                 <ButtonExport onClick={pdfCallback}>
                    <FontAwesomeIcon size='lg' icon="file-pdf" color="#ff0000" />
                </ButtonExport>
            )}
            {!pdfOnly && (
            <>
                <ButtonExport onClick={handleExcelExport}>
                    <FontAwesomeIcon size='lg' icon="file-excel" color="#1d6f42" />
                </ButtonExport>
                <CSVLink data={getCsvFormattedData(data, columns, exportIgnoreColumns)} filename={`${fileNameNoExt || title}.csv`}>
                    <ButtonExport>
                        <FontAwesomeIcon size='lg' icon="file-csv" color="grey" />
                    </ButtonExport>
                </CSVLink>
            </>
            )}
        </DivRow>
    )
}