import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@mui/material/Tooltip'
import styled from 'styled-components'

const Icon = styled(FontAwesomeIcon)`
      margin-right: 5px;
`

export default function Eyecon(props) {
    const { slash, userInfo } = props
    const isAirlineEmployee = userInfo?.isAirlineEmployee

    return isAirlineEmployee ? (
        <Tooltip
            title={(
                <span>
                    {slash ? 'Internal Only' : 'Customer Visible'}
                </span>
            )}
        >
            <span>
                <Icon icon={slash ? 'eye-slash' : 'eye'} />
            </span>
        </Tooltip>
    ) : null
}