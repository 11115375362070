import React from 'react'
import Eyecon from 'pageComponents/_common/Eyecon'
import MultiInput from 'pageComponents/Checkout2/components/MultiInput'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { FlexChild, FlexInline, FullWidth, StyledCheckbox, Textarea } from '../styledComponents'

export default function Email() {
    const {
        checkoutEditableFields, 
        setCheckoutEditableFields, 
    } = useCheckout2();

    return (
        <FullWidth>
            <FlexChild>
                <label htmlFor="sendEmailToShipTo">{`Send confirmation email to ${checkoutEditableFields.SelectedContact_Email}?`}</label>
                <StyledCheckbox
                    type="checkbox"
                    id="sendEmailToShipTo"
                    name="sendEmailToShipTo"
                    checked={checkoutEditableFields.SendToShipTo}
                    onChange={(e) => setCheckoutEditableFields({...checkoutEditableFields, SendToShipTo: e.target.checked })}
                />
            </FlexChild>
            <FlexInline>
                <Eyecon {...{ userInfo: { isAirlineEmployee: true }, slash: false }} />
                <FullWidth>
                    <Textarea
						id={`EmailNotes`}
                        value={checkoutEditableFields.EmailNotes}
                        label="Email Notes"
                        onChange={(e) => setCheckoutEditableFields({...checkoutEditableFields, EmailNotes: e.target.value })}
                        rows={3}
                        maxLength={255}
                    />
                    <MultiInput {...{
                        label: 'CC Emails',
                        addLabel: 'Add a CC email',
						addLabelId: 'email',
                        value: checkoutEditableFields.CcEmails,
                        setValue: (val) => setCheckoutEditableFields({...checkoutEditableFields, CcEmails: val })}
                    } />
                </FullWidth>
            </FlexInline>
        </FullWidth>
    )
}
