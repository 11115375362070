import React, { useEffect, useState } from 'react'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { AreaContentRow, AreaHeading, Area, LayoutArea, FullWidth, FlexChild, StyledCheckbox, HeaderActionButton } from '../styledComponents'
import { contactSchemaAnon } from '../util/validationSchema'
import Required from 'pageComponents/_common/required'
import Input from 'pageComponents/_common/formik/input_v2'
import { CheckCircle, InfoRounded } from '@mui/icons-material'
import { ErrorMessage, Form, Formik } from 'formik'
import { FormikFormContainer, FormikFormFieldError, FormikFormGroup } from 'styles/formikForm'
import PasswordRequirements from 'pageComponents/PasswordReset/uiComponents/passwordRequirements'
import FormikInput from '../../_common/formik/input_v2'
import { justPasswordValidateSchema } from 'pageComponents/Signup/validationSchemas'
import Loader from 'pageComponents/_common/loader'
import { Link } from 'react-router-dom'
import { ErrorAlert, InfoAlert } from 'styles/alerts'
import { Tooltip } from '@mui/material'

export default function ContactInfoAnon() {
	const {
		checkoutEditableFields,
		setCheckoutEditableFields,
		validationStatus,
		inPageRefs,
		setOpenEditorField,
		addPassword
	} = useCheckout2();
	//Monitor the validation state of the NewAccount section, to let CheckoutProviderFields track the password editor  
	useEffect(() => {
		setOpenEditorField('newAccount', !(validationStatus.NewAccountStatus.Status === "Ok" || validationStatus.NewAccountStatus.Status === "Disabled"));
	}, [validationStatus]);

	const [newAcctPassword] = useState({ password: "", verifyPassword: "", passwordStrength: false });

	//Called by formik, passes value down to CheckoutProviderFields to add the password to the Checkout record
	function savePassword(passwordFormValues) {
		addPassword(passwordFormValues.password);
	}

	const handleValidateFields = values => {
		contactSchemaAnon
			.isValid(values).then((valid) => {
				if (valid) {
					setCheckoutEditableFields({ ...checkoutEditableFields, SelectedContact_Email: values.SelectedContact_Email });
				}
			});
	}

	return (
		<Area>
			<AreaHeading ref={inPageRefs.contactSectionRef}>
				<h1>Contact</h1>
				<CheckCircle titleAccess={validationStatus.IsContactValid ? 'Contact section is valid' : 'Contact section is not valid'} label='Contact' htmlColor={validationStatus.IsContactValid ? 'green' : '#CDC'} />
			</AreaHeading>
			<LayoutArea>
				<AreaContentRow>
					<Formik
						initialValues={checkoutEditableFields}
						enableReinitialize={false}
						validationSchema={contactSchemaAnon}
						validate={handleValidateFields}
						validateOnBlur={true}
						validateOnChange={true}
					>
						<Form>
							<Input type={'email'}
								label={<>Email<Required /></>}
								name="SelectedContact_Email"
							/>
						</Form>
					</Formik>
				</AreaContentRow>
				<AreaContentRow>
					<FullWidth>
						<FlexChild>
							<label htmlFor="IsCreateAccount">Create an account for next time? <Tooltip arrow
									title={(
										<span style={{ fontSize: '12px', color: 'white', lineHeight: '18px' }}>
											You can create a <i>new</i> account or check out as a guest. If you belong to an organization that has done business with Airline Hydraulics in the past, you should log in first or request an account using the Request Account page at the top of the screen. Airline Hydraulics can't merge customer accounts or move order history between accounts.
										</span>
									)}>
								 <InfoRounded fontSize='0.7rem' color='info' />
							</Tooltip></label>
							<StyledCheckbox
								id="IsCreateAccount"
								name="IsCreateAccount"
								type="checkbox"
								checked={checkoutEditableFields.IsCreateAccount}
								onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, IsCreateAccount: e.target.checked })}
							/>
						</FlexChild>
					</FullWidth>
				</AreaContentRow>
				{/* Create account - Require password */}
				{!!checkoutEditableFields.IsCreateAccount && <>
					{validationStatus.NewAccountStatus.Status === "DuplicateEmail" &&
						<AreaContentRow>
							<FullWidth>
								<FlexChild>
									<ErrorAlert ref={inPageRefs.newAccountSectionRef} style={{ textAlign: 'center' }}>
										The email you're using to checkout is already registered. <Link to="/login">Log in?</Link>
									</ErrorAlert>
								</FlexChild>
							</FullWidth>
						</AreaContentRow>
					}
					{validationStatus.NewAccountStatus.Status === "InvalidEmail" &&
						<AreaContentRow>
							<FullWidth>
								<FlexChild>
									<ErrorAlert ref={inPageRefs.newAccountSectionRef} style={{ textAlign: 'center' }}>
										The email you're using to checkout is not valid.
									</ErrorAlert>
								</FlexChild>
							</FullWidth>
						</AreaContentRow>
					}
					{validationStatus.NewAccountStatus.Status === "NeedsPassword" &&
						<AreaContentRow>
							<FullWidth>
								<FlexChild>
									<span ref={inPageRefs.newAccountSectionRef} style={{ fontStyle: 'italic', textAlign: 'center' }}>
										<Required /> Set a password below, and then click Save Password
									</span>
								</FlexChild>
							</FullWidth>
						</AreaContentRow>
					}
					{validationStatus.NewAccountStatus.Status === "Ok" &&
						<AreaContentRow>
							<FullWidth>
								<FlexChild>
									<InfoAlert ref={inPageRefs.newAccountSectionRef} style={{ textAlign: 'center' }}>
										<CheckCircle titleAccess='Your Password Is Saved' label='Password Saved' htmlColor='green' />
										We will create a new account for you when you check out, using your saved password.
									</InfoAlert>
								</FlexChild>
							</FullWidth>
						</AreaContentRow>
					}
					{validationStatus.NewAccountStatus.Status !== "Ok" &&
						<AreaContentRow>
							<Formik
								initialValues={newAcctPassword}
								enableReinitialize={false}
								validateOnBlur={true}
								validateOnChange={true}
								validateOnMount={true}
								validationSchema={justPasswordValidateSchema}
								onSubmit={(values, actions) => { savePassword(values); actions.setSubmitting(false); }}
							>
								{({ props, values, setFieldValue, isSubmitting, isValid }) => (<Form>
									<FormikFormContainer>
										<FormikFormGroup>
											<h4>Create a new account</h4>
											<FormikInput id={`Password`} label={<>Password<Required /></>} type="password" name="password" />
											<FormikInput id={`Confirm-Password`} label={<>Confirm Password<Required /></>} type="password" name="verifyPassword" />
											<PasswordRequirements
												password={values.password}
												confirmPassword={values.verifyPassword}
												isValidPassword={(v) => { console.log('isvalid', v); setFieldValue('passwordStrength', v); }}
											/>
											<FormikFormFieldError style={{ width: '400px' }}>
												<ErrorMessage name="passwordStrength" />
											</FormikFormFieldError>
											{isSubmitting && <Loader />}
											<HeaderActionButton id={`password-SaveBtn`} type="submit" name="submit" disabled={!isValid}>Save Password</HeaderActionButton>
										</FormikFormGroup>
									</FormikFormContainer>
								</Form>)}
							</Formik>
						</AreaContentRow>
					}
				</>}
			</LayoutArea>
		</Area >
	)
}