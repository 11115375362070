const cartFileUrl = `${process.env.REACT_APP_API_URL}/api/FileUpload/file`
const itemCreationFileUrl = `${process.env.REACT_APP_API_URL}/api/FileUpload/itemdocfile`

export function uploadFile(file, area) {
  const bearerToken = localStorage.getItem('apiToken')
  const refreshToken = localStorage.getItem('refreshToken')
  const bearer = `Bearer ${bearerToken}`
  const refresh = `RefreshToken ${refreshToken}`

  const fileUrl = area === 'itemCreation' ? itemCreationFileUrl : cartFileUrl
  
  const data = new FormData()
  data.append('file', file)
  return fetch(fileUrl,
    {
      method: 'POST',
      headers: {
        'Authorization': bearer,
        'refreshToken': refresh,
      },
      body: data
    })
}