import React from 'react'
import { Box, BoxContainer, AlertSpan } from '../../styledComponents'
import styled from 'styled-components';

const SpanDiff = styled.span`
	background-color: yellow;
`;

const  MaybeDiffSpan = (props) => {
	return (!!props.a ? ((props.b !== props.a) ? <SpanDiff>{props.a}</SpanDiff> : <span>{props.a}</span>) : <></>);
}

export default function AddressBox(props) {
    const {
        Name,
		Attn,
        Address1,
        Address2,
        City,
        State,
        Zip,
        DeliveryInstructions,
        FirstName,
        LastName,
        Country
    } = props.address;

    const hasContent = [
		Name,
		Attn,
        Address1,
        Address2,
        City,
        State,
        Zip,
        FirstName,
        LastName].some(str => str && `${str}`.trim() !== '');
	
	if(!hasContent) {
		return (<></>)
	} else if(props.highlightDifferencesSource) {
		return (<>
			{hasContent && <Box>
				<BoxContainer>
					<MaybeDiffSpan a={Name} b={props.highlightDifferencesSource.Name} />
					<span><MaybeDiffSpan a={FirstName} b={props.highlightDifferencesSource.FirstName} /> <MaybeDiffSpan a={LastName} b={props.highlightDifferencesSource.LastName} /></span>
					<MaybeDiffSpan a={Attn} b={props.highlightDifferencesSource.Attn} />
					<MaybeDiffSpan a={Address1} b={props.highlightDifferencesSource.Address1} />
					<MaybeDiffSpan a={Address2} b={props.highlightDifferencesSource.Address2} />
					<span>
						<MaybeDiffSpan a={City} b={props.highlightDifferencesSource.City} />,&nbsp;
						<MaybeDiffSpan a={State} b={props.highlightDifferencesSource.State} />&nbsp;
						<MaybeDiffSpan a={Zip} b={props.highlightDifferencesSource.Zip} />&nbsp;
						<MaybeDiffSpan a={Country} b={props.highlightDifferencesSource.Country} /> 
					</span>
					{!props.hideExtra && DeliveryInstructions && <span style={{ fontStyle: "italic" }}>{DeliveryInstructions}</span>}
				</BoxContainer>
				{/* {alert && <AlertSpan>{alert}</AlertSpan>} */}
			</Box>}
		</>)
	} else {
    	return (<>
			{hasContent && <Box>
				<BoxContainer>
					{Name && <span>{Name}</span>}
					{FirstName && <span>{FirstName} {LastName}</span>}
					{Attn && <span>{Attn}</span> }
					<span>{Address1}</span>
					{Address2 && <span>{Address2}</span>}
					<span>{City}, {State} {Zip} {Country}</span>
					{!props.hideExtra && DeliveryInstructions && <span style={{ fontStyle: "italic" }}>{DeliveryInstructions}</span>}
				</BoxContainer>
				{/* {alert && <AlertSpan>{alert}</AlertSpan>} */}
			</Box>}
		</>)
	}
}
