import React from 'react'

export default function CopyToClipboard(props) {
  const { children, text } = props;

  return (
    <div onClick={() => navigator.clipboard.writeText(text)}>
      {children}
    </div>
  )
}