const { object, string, boolean, array, number } = require('yup')

export const contactSchema = object({
    FirstName: string('First Name is required')
        .max(50)
        .required('First Name is required')
        .matches(/[^\s\\]/, 'Please remove the leading space character'),
    LastName: string('Last Name is required')
        .max(50)
        .required('Last Name is required')
        .matches(/[^\s\\]/, 'Please remove the leading space character'),
    Phone: string('Phone Number is required')
        .min(10, 'Phone Number must be at least 10 characters long')
        .required('Phone Number is required')
        .matches(/[^\s\\]/, 'Please remove the leading space character'),
    Email: string('Email is not valid')
        .max(255)
        .email('Email is not valid')
        .required('Email is required')
        .matches(/[^\s\\]/, 'Please remove the leading space character'),
})

export const contactSchemaAnon = object({
    SelectedContact_Email: string('Email is not valid')
        .max(255)
        .email('Email is not valid')
        .required('Email is required')
        .matches(/[^\s\\]/, 'Please remove the leading space character'),
})

export const addressSchema = object({
    Address1: string('Address Line 1 is required')
        .min(5, 'Address Line 1 must be at least 5 characters long')
        .max(50)
        .nullable('Address Line 1 is required')
        .required('Address Line 1 is required'),
    City: string('City is required')
        .min(2, 'City must be at least 2 characters longggg')
        .max(50)
        .nullable('City is required')
        .required('City is required'),
    State: string('State/Province must be selected')
        .min(2)
        .required('State/Province must be selected'),
    Zip: string('Zip/Postal Code is required')
        .min(5, 'Zip/Postal Code must be at least 5 characters long (6 in Canada)')
        .max(10)
        .nullable('Zip/Postal Code is required')
        .required('Zip/Postal Code is required'),
    Country: string('Country must be selected')
        .min(2)
        .required('Country must be selected'),
    Phone: string('Phone is required')
        .nullable('Phone is required')
        .min(10, 'Phone is required (xxx-xxx-xxxx)')
        .required('Phone is required')
})
