import React, { useContext, useEffect, useState } from 'react'
import { ButtonBlack } from 'styles/buttons'
import {
    AreaContentRow, AreaHeading, Bold, HorizontalPack, Area, Textarea, FlexInline,
    FullWidth, EyeSpace, FlexChild, LayoutArea, StyledCheckbox, HeaderActionButton
} from '../styledComponents'
import DocumentLinkModal from 'pageComponents/ShoppingCart/uiComponents/DocumentLinkModal'
import Email from '../components/Email'
import Eyecon from 'pageComponents/_common/Eyecon'
import MyContext from 'setup/context'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { CheckCircle } from '@mui/icons-material'
import AddressViewEdit from '../components/address/AddressViewEdit'
import AddressBox from '../components/address/AddressBox'


export default function OtherInfo(props) {
    const { userInfo } = useContext(MyContext);
    const {
        checkoutEditableFields, 
        setCheckoutEditableFields, 
        setCheckoutEditableField,
        validationStatus,
        inPageRefs,
		openEditors,
		showBillingAddressEditor,
		setShowBillingAddressEditor
    } = useCheckout2();

    const [showAttach, setShowAttach] = useState(false);
    const [showPaperwork, setShowPaperwork] = useState(false);

	//Monitor the checkbox for Billing same as Shipping. If it is checked, then the address editor is not open
    useEffect(() => {
        if(checkoutEditableFields.IsBillingSameAsShipping) {
			console.log("closing the billing editor...")
            setShowBillingAddressEditor(false);
        } 
    }, [checkoutEditableFields]);

    const billingAddress = {
        Name: checkoutEditableFields.BillingAddress_Name,
        FirstName: checkoutEditableFields.BillingAddress_FirstName,
        LastName: checkoutEditableFields.BillingAddress_LastName,
        Address1: checkoutEditableFields.BillingAddress_Address1,
        Address2: checkoutEditableFields.BillingAddress_Address2,
        City: checkoutEditableFields.BillingAddress_City,
        State: checkoutEditableFields.BillingAddress_State,
        Zip: checkoutEditableFields.BillingAddress_Zip,
        Country: checkoutEditableFields.BillingAddress_Country,
        Phone: checkoutEditableFields.BillingAddress_Phone,
        PhoneExt: checkoutEditableFields.BillingAddress_PhoneExt,
    };
    const [currentEditingAddress, setCurrentEditingAddress] = useState(billingAddress);

    const shippingAddress = {
        Name: checkoutEditableFields.ShippingChoices_Name,
        FirstName: checkoutEditableFields.ShippingChoices_FirstName,
        LastName: checkoutEditableFields.ShippingChoices_LastName,
        Address1: checkoutEditableFields.ShippingChoices_Address1,
        Address2: checkoutEditableFields.ShippingChoices_Address2,
        City: checkoutEditableFields.ShippingChoices_City,
        State: checkoutEditableFields.ShippingChoices_State,
        Zip: checkoutEditableFields.ShippingChoices_Zip,
        Country: checkoutEditableFields.ShippingChoices_Country,
        Phone: checkoutEditableFields.ShippingChoices_Phone,
        PhoneExt: checkoutEditableFields.ShippingChoices_PhoneExt,
    };


	const billToValid = validationStatus.IsBillToValid && !openEditors.billing && !openEditors.billingAddress;

    return (
        <Area>
            <AreaHeading ref={inPageRefs.billingAndOtherSectionRef}>
                <h1>Billing &amp; Other</h1>
                <CheckCircle titleAccess={billToValid ? 'Bill To section is valid' : 'Bill To section is not valid'} label='Bill to is Valid' htmlColor={billToValid ? 'green' : '#CDC'}/>
            </AreaHeading>
            <LayoutArea>
                <AreaContentRow>
                    <FlexChild>
                        <label htmlFor='IsBillingSameAsShipping'>Billing address same as shipping</label>
                        <StyledCheckbox
                            id='IsBillingSameAsShipping'
                            name='IsBillingSameAsShipping'
                            type="checkbox"
                            checked={checkoutEditableFields.IsBillingSameAsShipping}
                            onChange={e => setCheckoutEditableField('IsBillingSameAsShipping', e.target.checked)}
                        />
                    </FlexChild>
                </AreaContentRow>
                <AreaContentRow>
                    {!checkoutEditableFields.IsBillingSameAsShipping && 
                        <AddressViewEdit 
                            address={currentEditingAddress}
                            addressUpdatedCallback={(addr) => setCurrentEditingAddress(addr)}
                            addressType="billing"
                            showEditor={showBillingAddressEditor}
                            setShowEditor={setShowBillingAddressEditor}
                        />
                    }
                    {!!checkoutEditableFields.IsBillingSameAsShipping &&
                        <AddressBox address={shippingAddress} />
                    }
                </AreaContentRow>
                <AreaContentRow>
                    <Bold>Order Notes</Bold>
                </AreaContentRow>
                <AreaContentRow>
                    <FullWidth>
                        <FlexInline>
                            {userInfo?.isImpersonatorUser ? <Eyecon {...{ userInfo }} /> : <EyeSpace />}
                            <Textarea
								id={`Notes`}
                                placeholder="(optional)"
                                value={checkoutEditableFields.Notes}
                                rows={3}
                                onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, Notes: e.target.value })}
                            />
                        </FlexInline>
                    </FullWidth>
                </AreaContentRow>
                {userInfo?.isImpersonatorUser && (<>
                    <AreaContentRow>
                        <Bold>Confirmation Email</Bold>
                    </AreaContentRow>
                    <AreaContentRow>
                        <Email />
                    </AreaContentRow></>
                )}
                
                {userInfo && (<>
                    <AreaContentRow>
                        <Bold>Attachments &amp; Documentation</Bold>
                    </AreaContentRow>
                    <AreaContentRow>
                        {!showPaperwork && (
                            <HorizontalPack>
                                <EyeSpace />
                                <ButtonBlack id="AttachFilesBtn" onClick={() => setShowAttach(true)}>Attach Files</ButtonBlack>
                            </HorizontalPack>
                        )}
                    </AreaContentRow>
                    {showAttach && (
                        <DocumentLinkModal
                            {...{
                                open: showAttach,
                                hide: () => setShowAttach(false),
                                accept: 'image/jpeg, application/pdf, image/png',
                                allowedMessage: 'Maximum 5 files, 10MB size. Only jpg, png or pdf allowed.',
                                maxFiles: 5,
                            }}
                        />
                    )}
                </>)}
            </LayoutArea>
        </Area >
    )
}
