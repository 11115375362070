import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FullFlexCol, FlexInline, Label } from 'pageComponents/Checkout2/styledComponents'

export default function MultiInput(props) {
    const { addLabel, label, value, setValue, addLabelId } = props

    function setInput(idx, e) {
        const copy = [...value]
        copy[idx] = e.target.value
        setValue(copy)
    }

    function addInput() {
        setValue([...value, ''])
    }

    function removeInput(idx) {
        const copy = [...value]
        copy.splice(idx, 1)
        setValue(copy)
    }

    const inputs = value?.map((val, idx) => {
        return (
            <FlexInline key={idx}>
                <input
					id={addLabelId}
                    value={val}
                    onChange={(e) => setInput(idx, e)}
                />
                <div onClick={() => removeInput(idx)}>
                    <FontAwesomeIcon icon="minus-circle" color="grey" />
                </div>
                {((idx + 1) === value.length && idx < 4) && (
                    <div onClick={addInput}>
                        <FontAwesomeIcon icon="plus-circle" color="#328EFC" />
                    </div>
                )}
            </FlexInline>
        )
    })

    return (
        <div>
            <Label>{label}</Label>
            {inputs.length > 0 ? (
                <FullFlexCol>{inputs}</FullFlexCol>
            ) : (
                <FlexInline>
                    <div>{addLabel}</div>
                    <div onClick={addInput}>
                        <FontAwesomeIcon icon="plus-circle" color="#328EFC" />
                    </div>
                </FlexInline>
            )}
        </div>
    )
}
