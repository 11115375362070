import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { buildSearchString } from 'pageComponents/_common/helpers/generalHelperFunctions'

const ImgDiv = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	height: 250px;
`

const Img = styled.img`
	width: 100%;
	max-width: 180px;
	object-fit: contain;
`

const ProductsDetails = styled.div`
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	margin: 0px 30px 0 30px;
	align-content: center;
	flex-direction: column;
	flex: 2;
	align-items: center;
	justify-content: center;
`

const ProductsH4 = styled.a`
	margin: 0 auto;
	color: 	#000000;
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: bold;
	&:hover{
		color: #b51029;
		text-decoration: none;
	}
`
const ProductName = styled.p`
	margin: 0 auto;
	color: 	#000000;
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: bold;
`
const ShopProducts = styled.a`
	color: #246696;
	font-size: 14px;
	margin-top: 10px;
`

const ShowMoreBtn = styled.button`
	font-size: 14px;
	border: none;
	border-radius:2em;
	padding: 4px 10px;
	outline: none;
	background-color: #f2f3f4;
	text-align: left;
`

const ProductsDiv = styled.div`
	display: flex;  
	flex-wrap: wrap;
	margin: 20px 0 20px 0;
	background-color:  #f2f3f4;
	padding: 15px;
	width: 100%;
	flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'}; 
	@media (max-width: 768px) {
		flex-direction: column;
	}
`
const Div1 = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
`

export default function ProductItems(props) {
	const [showText, setShowText] = useState(false)
	const {
		text,
		src,
		additionalText,
		title,
		reverse,
		searchTerm,
		learnMoreLink,
		brand,
		selectedCategoryId,
		shopUrl,
		noUrl
	} = props

	const searchItem = { searchTerm: `${searchTerm ? searchTerm : ''} ${title}`, selectedCategoryId: selectedCategoryId }
	const searchForItem = buildSearchString(searchItem)

	return (
		<ProductsDiv reverse={reverse}>
			<ImgDiv><Img src={src} /></ImgDiv>
			<ProductsDetails>

				{(noUrl ? <ProductName>{title}</ProductName> : (
					<ProductsH4 href={shopUrl ? shopUrl : (learnMoreLink ? learnMoreLink : searchForItem)}>
						{title}
					</ProductsH4>
				))}

				{text}
				<Div1>
				{(additionalText) &&
					<ShowMoreBtn onClick={() => setShowText(!showText)}>{showText ? <><FontAwesomeIcon icon='minus-circle' size='1x' /> Show Less </> : <><FontAwesomeIcon icon='plus-circle' size='1x' /> Show More  </>}</ShowMoreBtn>
				}
				{showText && additionalText}
				</Div1>
				{(noUrl ?
					<></> :
					<Div1>
						<ShopProducts href={shopUrl ? shopUrl : (learnMoreLink ? learnMoreLink : searchForItem)}>
							<FontAwesomeIcon icon='arrow-circle-right' size='1x' /> {learnMoreLink ? 'Learn more and Shop for' : 'Shop for'} {title}
						</ShopProducts>
					</Div1>
				)}
			</ProductsDetails>
		</ProductsDiv>

	)
}