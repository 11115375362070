import { CheckCircle, InfoRounded } from '@mui/icons-material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import MyContext from 'setup/context'
import CardOption from '../components/CardOption'
import PaymentOptions from '../components/PaymentOptions'
import {
    Area,
    AreaContentRow,
    AreaHeading,
    Bold,
    FlexColBordered,
    FlexInline,
    FullWidth,
    LayoutArea,
    StyledCheckbox,
} from '../styledComponents'
import Required from 'pageComponents/_common/required'
import StripePaymentSection from 'pageComponents/Checkout/uiComponents/stripePayment'
import styled from 'styled-components'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Loader from 'pageComponents/_common/loader'
import { Tooltip } from '@mui/material'

const WrapSelect = styled.div`
    margin-left: -5px;
`

const POWrapper = styled.div`
    display:flex;
    flex-direction: column;

    > input {
        width: 250px;
        height: 40px;
        padding: 0 8px;
        color: #303030;
        font-size: 16px;
        border-radius: 1px;
        border: 1px solid #e1e1e1;
    }
`

const POError = styled.span`
        display: block;
        color: black;
        font-size: 14px;
        font-weight: bold;
        padding-left: 4px;
        margin-right: -4px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        padding: 2px;
        width: 100%;
        text-align: right;
        font-style: italic;
`

const DuplicatePOWrapper = styled.label`
    color: red;
    margin-bottom: 0;
    align-self: center;
    + input {
        align-self: center;
        margin-left: 10px;
        height: 15px;
        width: 15px;
        cursor: pointer;
    }
`

export default function PaymentInfo() {
    const {
        checkoutInitializeData,
        checkoutEditableFields,
        setCheckoutEditableFields,
        validationStatus,
        addPaymentMethod,
        checkoutCardsLoading,
        inPageRefs
    } = useCheckout2();

    const { userInfo } = useContext(MyContext);
    const stripe = useStripe();
    const elements = useElements();

    const [cardIsValid, setCardIsValid] = useState(false);

    const cardOptions = checkoutInitializeData.StripeSystemInfo?.SavedPaymentMethods.map(p => {
        const { Label, Value } = p;

        return (
            <CardOption {...{
                key: Value,
                label: Label,
                onClick: () => {
                    setCheckoutEditableFields({
                        ...checkoutEditableFields,
                        Stripe_StripeCardToken: Value
                    });
                },
                selected: (Value === checkoutEditableFields.Stripe_StripeCardToken || (Value === "new_card" && checkoutEditableFields.Stripe_StripeCardToken === null))
            }} />
        )
    })

    useEffect(() => {
        if (cardIsValid) {
            //setIsSaving(true);
            if (checkoutEditableFields.PaymentOption === 'CreditCard') {
                if (!checkoutEditableFields.Stripe_StripeCardToken || checkoutEditableFields.Stripe_StripeCardToken === 'new_card') {
                    //Saving with a new CC
                    if (!stripe || !elements) {
                        // Stripe.js has not yet loaded.
                        //setIsSaving(false)
                        return;
                    }

                    const cardElement = elements.getElement(CardElement);
                    stripe
                        .confirmCardSetup(checkoutInitializeData.StripeSystemInfo.PaymentSystemClientSecret, { payment_method: { card: cardElement } })
                        .then(data => {
                            console.log("CCS", data);
                            if (data.error) {
                                alert(data.error.message)
                                //setIsSaving(false)
                                return
                            }

                            if (data.setupIntent) {
                                addPaymentMethod(data.setupIntent.payment_method);
                            } else {
                                alert('There was an issue setting up that credit card')
                                //setIsSaving(false)
                            }
                        });
                }
            }
        }
    }, [cardIsValid]);

    const CardSection = () => {
        return (
            <>
                <AreaContentRow>
                    <StripePaymentSection setCardIsValid={setCardIsValid} />
                </AreaContentRow>
            </>)
    }

    //Memoize the stripe card section so that re-renders don't reset its value
    const CardMemo = useMemo(() => {
        if (checkoutInitializeData) {
            return <CardSection />
        } else {
            return <></>
        }
    }, [checkoutInitializeData])

    return (
        <Area>
            <AreaHeading ref={inPageRefs.paymentSectionRef}>
                <h1>Payment Method</h1>
                <CheckCircle titleAccess={validationStatus.IsPaymentValid ? 'Payment method is valid' : 'Payment method is not valid'} label='Payment is Valid' htmlColor={validationStatus.IsPaymentValid ? 'green' : '#CDC'} />
            </AreaHeading>
            <LayoutArea>
                {!checkoutEditableFields.IsQuote && <>
                    <AreaContentRow>
                        {/* po or credit_card choice */}
                        {checkoutInitializeData.PaymentOptions.length > 0 &&
                            <PaymentOptions
                                options={checkoutInitializeData.PaymentOptions}
                                selectedPaymentOption={checkoutEditableFields.PaymentOption}
                                setSelectedPaymentOption={
                                    (o) => setCheckoutEditableFields({ ...checkoutEditableFields, PaymentOption: o.Value })
                                }
                            />}
                    </AreaContentRow>
                    {checkoutEditableFields.PaymentOption === "CreditCard" && <>
                        <AreaContentRow>
                            <FlexColBordered>
                                <WrapSelect>
                                    {/* TODO, this might need to be scrollable if there are a LOT of saved cards */}
                                    {cardOptions}
                                </WrapSelect>
                            </FlexColBordered>
                        </AreaContentRow>
                        {checkoutCardsLoading && <Loader />}
                        {(checkoutEditableFields.Stripe_StripeCardToken === "new_card" || !checkoutInitializeData.StripeSystemInfo.SavedPaymentMethods.find(pm => pm.Value === checkoutEditableFields.Stripe_StripeCardToken)) && <>
                            <AreaContentRow>
                                <WrapSelect>
                                    {CardMemo}
                                </WrapSelect>
                            </AreaContentRow>
                            {!!userInfo &&
                            <AreaContentRow style={{ justifyContent: "flex-end" }}>
                                <label htmlFor="rememberCard">Remember this card?</label>
                                <StyledCheckbox
                                    type="checkbox"
                                    id="rememberCard"
                                    name="rememberCard"
                                    checked={checkoutEditableFields.Stripe_IsRememberCard}
                                    onChange={e => setCheckoutEditableFields({ ...checkoutEditableFields, Stripe_IsRememberCard: e.target.checked })} />
                            </AreaContentRow>
                            } 
                        </>} 
                    </>}
                </>}
                <AreaContentRow>
                    <FullWidth>
                        <FlexInline id='poNumber'>
                            <Bold>PO Number <Tooltip arrow
									title={(
										<span style={{ fontSize: '12px', color: 'white', lineHeight: '18px' }}>
											This is your reference number for the order. {checkoutInitializeData.BillingInformation.IsPoNumberRequired && <>Your account requires this to be filled out.</>}
										</span>
									)}>
								 <InfoRounded fontSize='0.7rem' color='info' />
							</Tooltip></Bold>
                            {checkoutInitializeData.BillingInformation.IsPoNumberRequired && <Required />}
                        </FlexInline>
                    </FullWidth>
                </AreaContentRow>
                <AreaContentRow>
                    <FullWidth>
                            <POWrapper>
                                <input
								    id={`PoNumberOrJobName`}
                                    type="text"
                                    value={checkoutEditableFields.PoNumberOrJobName}
                                    onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, PoNumberOrJobName: e.target.value })}
                                />
                                {validationStatus.IsPOValid?.Status !== "Valid" && (
                                    <POError>{validationStatus.IsPOValid?.Message}</POError>
                                )}
                                {validationStatus.IsPOValid?.Status === "Duplicate" && (
                                    <FlexInline>
                                        <DuplicatePOWrapper>Submit order with duplicate PO?</DuplicatePOWrapper>
                                        <input id={`ConfirmDupPoNumberOrJobName`} type='checkbox' value={checkoutEditableFields.IsDuplicatePoOverride} onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, IsDuplicatePoOverride: e.target.checked })} />
                                    </FlexInline>
                                )}
                            </POWrapper>
                    </FullWidth>
                </AreaContentRow>
            </LayoutArea>
        </Area>
    )
}
