import { gtag } from 'ga-gtag';

function hubSpotShipToFormTracking(shipto) {

    // Tracking checkout form in HubSpot start here
    const portalId = 8466844;
    const formGuid = '7628ce06-1a65-4456-ba90-f94c5a624fea';

    const contactInfo = [
        {
            name: 'firstname',
            value: shipto.firstName,
        },
        {
            name: 'lastname',
            value: shipto.lastName,
        },
        {
            name: 'email',
            value: shipto.email,
        },
        {
            name: 'company',
            value: shipto.companyName,
        },
        {
            name: 'phone',
            value: shipto.phone,
        },
        {
            name: 'address',
            value: shipto.address1,
        },
        {
            name: 'city',
            value: shipto.city,
        },
        {
            name: 'zip',
            value: shipto.zip,
        },
        {
            name: 'state',
            value: shipto.stateOrProvince,
        },
    ];

    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                fields:
                    contactInfo,
            })
        }
    )
    .then(response => {
    })
    .catch(error => {
    });
}

//Adapts the checkout2 result set to work with checkout1 data
export function partnerNotificationOnOrderSubmitCheckout2(res) {
    partnerNotificationOnOrderSubmit({
        shipto: {
            firstName: res.ShipTo.FirstName,
            lastName: res.ShipTo.LastName,
            email: res.Email,
            companyName: res.ShipTo.Name,
            phone: res.ShipTo.Phone,
            address1: res.ShipTo.Address1,
            city: res.ShipTo.City,
            zip: res.ShipTo.Zip,
            stateOrProvince: res.ShipTo.State
        },
        isQuote: res.CheckoutType !== 'order',
        webReferenceId: res.WebReferenceId,
        isAirlineEmployee: res.IsPlacedByRep,
        affiliateName: res.AffiliateName,
        itemsSubtotal: res.ItemsSubTotal,
        taxTotal: res.TaxTotal,
        tariffTotal: res.TariffTotal,
        shippingCost: res.ShippingCost,
        grandTotal: res.GrandTotal,
        cartItems: res.CartItems.map((itm, idx) => {return {
            itemCode: itm.ItemCode,
            invMastUid: itm.InvMastUid,
            brand: itm.Brand,
            unitPrice: itm.UnitPrice,
            quantity: itm.Quantity
        };})
    });

}

export default function partnerNotificationOnOrderSubmit(args) {
    const {
        shipto,
        isQuote,
        webReferenceId,
        isAirlineEmployee,
        affiliateName,
        itemsSubtotal,
        taxTotal,
        tariffTotal,
        shippingCost,
        grandTotal,
        cartItems,
    } = args;

    if (!isQuote && webReferenceId && !isAirlineEmployee) {
        const cartItemsCode = cartItems.map(i => i.itemCode);
        // const dataLayer = window.dataLayer || [];
        // if (cartItemsCode.includes('MPSA-GOTCHA-01')) {
        //     dataLayer.push({
        //         event: 'Gotcha Stick transaction',
        //         internalUser: !!isAirlineEmployee,
        //         ecommerce: {
        //             purchase: {
        //                 actionField: {
        //                     id: webReferenceId, // Required 
        //                     affiliation: affiliateName,
        //                     revenue: grandTotal,  // Total transaction value (incl. tax and shipping) 
        //                     tax: taxTotal,
        //                     shipping: shippingCost
        //                 },
        //                 products: cartItems.map(item => {
        //                     return {
        //                         name: item.invMastUid, // Name or ID is required. 
        //                         id: item.itemCode,
        //                         price: item.unitPrice,
        //                         brand: item.brand,
        //                         quantity: item.quatity,
        //                     }
        //                 })
        //             }
        //         }
        //     })
        // }

        // dataLayer.push({
        //     event: 'transaction',
        //     internalUser: !!isAirlineEmployee,
        //     ecommerce: {
        //         purchase: {
        //             actionField: {
        //                 id: webReferenceId, // Required 
        //                 affiliation: affiliateName,
        //                 revenue: grandTotal,  // Total transaction value (incl. tax and shipping) 
        //                 tax: taxTotal,
        //                 shipping: shippingCost
        //             },
        //             products: cartItems.map(item => {
        //                 return {
        //                     name: item.invMastUid, // Name or ID is required. 
        //                     id: item.itemCode,
        //                     price: item.unitPrice,
        //                     brand: item.brand,
        //                     quantity: item.quatity,
        //                 }
        //             })
        //         }
        //     }
        // })

        gtag("event", "purchase", {
            transaction_id: webReferenceId,
            affiliation: affiliateName,
            value: grandTotal,
            tax: taxTotal,
            shipping: shippingCost,
            currency: "USD",
            items: cartItems.map ( item => {
                return {
                    item_id: item.itemCode,
                    item_name: item.invMastUid,
                    affiliation: affiliateName,
                    currency: "USD",
                    item_brand: item.brand,
                    price: item.unitPrice,
                    quantity: item.quatity
                }
            })
        });
        if (cartItemsCode.includes('MPSA-GOTCHA-01')){
            gtag("event", "Gotcha Stick transaction",{
                transaction_id: webReferenceId,
                affiliation: affiliateName,
                value: grandTotal,
                tax: taxTotal,
                shipping: shippingCost,
                currency: "USD",
                items: cartItems.map(item => {
                    return {
                        name: item.invMastUid, // Name or ID is required. 
                        id: item.itemCode,
                        price: item.unitPrice,
                        brand: item.brand,
                        quantity: item.quatity,
                    }
                })
            });
        }
        hubSpotShipToFormTracking(shipto);
    }
}