import React, { useContext, useState, useEffect, useRef } from 'react'
import useObjState from 'hooks/useObjState'
import { AirlineFetch, Methods } from 'pageComponents/_common/REST'
import { useDebounceValue } from 'pageComponents/_common/helpers/generalHelperFunctions';
import AddressBox from 'pageComponents/Checkout2/components/address/AddressBox';
import { ShowErrorAlert, ShowInfoAlert } from 'styles/alerts';
import { AreaContentRow, LayoutArea } from 'pageComponents/Checkout2/styledComponents';
import MyContext from './context';
import { useNavigate } from 'react-router';
import { partnerNotificationOnOrderSubmitCheckout2 } from 'pageComponents/Checkout2/partnerNotificationOrderComplete';

export const CheckoutContext = React.createContext();

export const useCheckout2 = () => useContext(CheckoutContext);

const { Provider } = CheckoutContext;

export default function CheckoutProvider({ children }) {
    const [checkoutInitializeDataIsLoading, setCheckoutInitializeDataIsLoading] = useState(true);
	const [isCheckoutInitializeFailed, setIsCheckoutInitializeFailed] = useState(false);
    const [checkoutSaveIsLoading, setCheckoutSaveIsLoading] = useState(false);
    const [checkoutCardsLoading, setCheckoutCardsLoading ] = useState(false);
    const [isApproveOrder, setIsApproveOrder] = useState(false);
    const [shippingDataIsLoading, setShippingDataIsLoading] = useState(false);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [shippingGroups, setShippingGroups] = useState([]);
    const [shippingNoRatesAvailable, setShippingNoRatesAvailable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState(<></>);
    const [containNcnrItem, setContainNcnrItem] = useState(false);
    const [acknowledgeNcnr, setAcknowledgeNcnr]= useState(false);

    const { emptyCart } = useContext(MyContext);
    const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if((checkoutInitializeDataIsLoading || checkoutSaveIsLoading || checkoutCardsLoading || shippingDataIsLoading) && !loading) {
			setLoading(true);
		} else if((!checkoutInitializeDataIsLoading && !checkoutSaveIsLoading && !checkoutCardsLoading && !shippingDataIsLoading) && loading) {						
			setLoading(false);
		}
	}, [checkoutInitializeDataIsLoading, checkoutSaveIsLoading, checkoutCardsLoading, shippingDataIsLoading])

    //API: CheckoutDto
    const [checkoutEditableFields, setCheckoutEditableFields, setCheckoutEditableField] = useObjState({
        ShoppingCartToken: null,
        //BillingAddress
        BillingAddress_P21Id: null,
        BillingAddress_Name: null,
        BillingAddress_FirstName: null,
        BillingAddress_LastName: null,
        BillingAddress_Address1: null,
        BillingAddress_Address2: null,
        BillingAddress_City: null,
        BillingAddress_State: null,
        BillingAddress_Zip: null,
        BillingAddress_Country: null,
        BillingAddress_Phone: null,
        BillingAddress_PhoneExt: null,
        //EndBillingAddress
        //EmailSettings
        CcEmails: [ ],
        EmailNotes: "",
        ImagesOnQuote: true,
        SendToShipTo: true,
        //EndEmailSettings
        IsQuote: null,
        IsCreateAccount: false,
        IsTaxExempt: null,
        Notes: "",
        PaymentOption: null,
        PoNumberOrJobName: null,
        QuoteReferenceNumber: null,
        QuoteExpirationDate: null,
        IsBillingSameAsShipping: false,
        //SelectedContact
        SelectedContact_P21Id: null,
        SelectedContact_FirstName: null,
        SelectedContact_LastName: null,
        SelectedContact_Title: null,
        SelectedContact_Phone: null,
        SelectedContact_PhoneExt: null,
        SelectedContact_Email: null,
        //EndSelectedContact
        //ShippingChoices
        ShippingChoices_SelectedShippingCarrier: null,
        ShippingChoices_IsCollect: null,
        ShippingChoices_CollectAccount: null,
        ShippingChoices_CollectCarrier: null,
        ShippingChoices_IsRush: null,
        ShippingChoices_NotBeforeDate: null,
        ShippingChoices_PackingBasis: null,
        ShippingChoices_SpecialPaperworkRequest: null,
        ShippingChoices_P21Id: null,
        ShippingChoices_Name: null,
        ShippingChoices_FirstName: null,
        ShippingChoices_LastName: null,
		ShippingChoices_Attn: null,
        ShippingChoices_Address1: null,
        ShippingChoices_Address2: null,
        ShippingChoices_City: null,
        ShippingChoices_State: null,
        ShippingChoices_Zip: null,
        ShippingChoices_Country: null,
        ShippingChoices_Phone: null,
        ShippingChoices_PhoneExt: null,
        ShippingChoices_DeliveryInstructions: null,
        ShippingChoices_ShippingSchedule: [],
        //EndShippingChoices
        //Stripe
        Stripe_StripeCustomerToken: null,
        Stripe_StripeCardToken: null,
        Stripe_IsRememberCard: null,
        //EndStripe
    });  
    const checkoutEditableFieldsDebounced = useDebounceValue(checkoutEditableFields);
    
    //API: CheckoutStatusResponse
    const [validationStatus, setValidationStatus] = useState({
		/* Assume everything is valid from the get-go */
        IsContactValid: true,
        IsShipToValid: true,
        IsShippingOptionValid: true,
        IsBillToValid: true,
        IsPaymentValid: true,
        IsReadyToCheckout: true,
        SubTotal: 0,
        ShippingTotal: 0,
        TaxTotal: 0,
        OrderTotal: 0,
        NewAccountStatus: { Status: 'Disabled', SavedUserName: ''},
    });

	useEffect(() => {
		//React to any validation problems present by opening relevant editors
		if(!validationStatus.IsContactValid) {
			setShowContactEditor(true);
		}
		if(!validationStatus.IsShipToValid) {
			setShowShippingAddressEditor(true);
		}
		if(!validationStatus.IsBillToValid) {
			setShowBillingAddressEditor(true);
		}
	},[validationStatus.IsContactValid,validationStatus.IsShipToValid,validationStatus.IsBillToValid]);

    //These refs handle scroll-to requests between different areas of the checkout page
    const newAccountSectionRef = useRef();
    const contactSectionRef = useRef();
    const shippingAddressSectionRef = useRef();
    const shippingChoicesSectionRef = useRef();
    const billingAndOtherSectionRef = useRef();
    const paymentSectionRef = useRef();
    const inPageRefs = {
        contactSectionRef,
        shippingAddressSectionRef,
        shippingChoicesSectionRef,
        billingAndOtherSectionRef,
        paymentSectionRef,
        newAccountSectionRef
    };

    //Some parts of checkout use discreet editors with their own Save logic,
    //  this object tracks whether any of those editors are open to prevent checkout
    //  if the user is still editing things
    const [openEditors, setOpenEditors, setOpenEditorField] = useObjState({
        contact: false,
        shippingAddress: false,
        shippingOptions: false,
        newAccount: false,
        billing: false,
        billingAddress: false
    });

    const [showBillingAddressEditor, _setShowBillingAddressEditor] = useState(openEditors.billingAddress);
    const [showShippingAddressEditor, _setShowShippingAddressEditor] = useState(openEditors.shippingAddress); 
    const [showShippingOptionsModal, _setShowShippingOptionsModal] = useState(openEditors.shippingOptions);
    const [showContactEditor, _setShowContactEditor] = useState(false);

    //Get the first open editor ref (so the section can be scrolled to)
    function getFirstOpenEditorInPageRef() {
        if(openEditors.contact) {
            return inPageRefs.contactSectionRef;
        } else if(openEditors.shippingAddress) {
            return inPageRefs.shippingAddressSectionRef;
        } else if(openEditors.shippingOptions) {
            return inPageRefs.shippingChoicesSectionRef;
        } else if(openEditors.newAccount) {
            return inPageRefs.newAccountSectionRef;
        } else if(openEditors.billing || openEditors.billingAddress) {
            return inPageRefs.billingAndOtherSectionRef;
        } else {
            return null;            
        }
    }

    function makeStructured(checkoutData) {
        return {
            ShoppingCartToken: checkoutData.CartToken,
            BillingAddress: {
                P21Id: checkoutData.BillingAddress_P21Id,
                Name: checkoutData.BillingAddress_Name,
                FirstName: checkoutData.BillingAddress_FirstName,
                LastName: checkoutData.BillingAddress_LastName,
                Address1: checkoutData.BillingAddress_Address1,
                Address2: checkoutData.BillingAddress_Address2, 
                City: checkoutData.BillingAddress_City,             
                State: checkoutData.BillingAddress_State,
                Zip: checkoutData.BillingAddress_Zip,
                Country: checkoutData.BillingAddress_Country,
                Phone: checkoutData.BillingAddress_Phone,
                PhoneExt: checkoutData.BillingAddress_PhoneExt,
            },
            EmailSettings: {
                CcEmails: checkoutData.CcEmails,
                EmailNotes: checkoutData.EmailNotes,
                ImagesOnQuote: checkoutData.ImagesOnQuote,
                SendToShipTo: checkoutData.SendToShipTo
            },
            IsQuote: checkoutData.IsQuote,
            IsCreateAccount: checkoutData.IsCreateAccount,
            IsTaxExempt: checkoutData.IsTaxExempt,
            Notes: checkoutData.Notes,
            PaymentOption: checkoutData.PaymentOption,
            PoNumberOrJobName: checkoutData.PoNumberOrJobName,
            QuoteReferenceNumber: checkoutData.QuoteReferenceNumber,
            QuoteExpirationDate: checkoutData.QuoteExpirationDate,
            IsBillingSameAsShipping: checkoutData.IsBillingSameAsShipping,
            SelectedContact: {
                P21Id: checkoutData.SelectedContact_P21Id,
                FirstName: checkoutData.SelectedContact_FirstName,
                LastName: checkoutData.SelectedContact_LastName,
                Title: checkoutData.SelectedContact_Title,
                Phone: checkoutData.SelectedContact_Phone,
                PhoneExt: checkoutData.SelectedContact_PhoneExt,
                Email: checkoutData.SelectedContact_Email
            },
            ShippingChoices: {
                SelectedShippingCarrier: checkoutData.ShippingChoices_SelectedShippingCarrier,
                IsCollect: checkoutData.ShippingChoices_IsCollect,
                CollectAccount: checkoutData.ShippingChoices_CollectAccount,
                CollectCarrier: checkoutData.ShippingChoices_SelectedCollectCarrier,
                IsRush: checkoutData.ShippingChoices_IsRush,
                NotBeforeDate: checkoutData.ShippingChoices_NotBeforeDate,
                PackingBasis: checkoutData.ShippingChoices_PackingBasis,
                SpecialPaperworkRequest: checkoutData.ShippingChoices_SpecialPaperworkRequest,
                P21Id: checkoutData.ShippingChoices_P21Id,
                Name: checkoutData.ShippingChoices_Name,
                FirstName: checkoutData.ShippingChoices_FirstName,
                LastName: checkoutData.ShippingChoices_LastName,
				Attn: checkoutData.ShippingChoices_Attn,
                Address1: checkoutData.ShippingChoices_Address1,
                Address2: checkoutData.ShippingChoices_Address2,
                City: checkoutData.ShippingChoices_City,
                State: checkoutData.ShippingChoices_State,
                Zip: checkoutData.ShippingChoices_Zip,
                Country: checkoutData.ShippingChoices_Country,
                Phone: checkoutData.ShippingChoices_Phone,
                PhoneExt: checkoutData.ShippingChoices_PhoneExt,
                DeliveryInstructions: checkoutData.ShippingChoices_DeliveryInstructions,
                ShippingSchedule: checkoutData.ShippingChoices_ShippingSchedule,
            },
            Stripe: {
                StripeCustomerToken: checkoutData.Stripe_StripeCustomerToken,
                StripeCardToken: checkoutData.Stripe_StripeCardToken,
                IsRememberCard: checkoutData.Stripe_IsRememberCard
            },
			IsDuplicatePoOverride: checkoutData.IsDuplicatePoOverride,
        };
    }

    //Preps the API data for use in Checkout2
    function makeFlat(checkoutDto) {
        let o = {
            CartToken: checkoutDto.ShoppingCartToken,
            //BillingAddress
            BillingAddress_P21Id: checkoutDto.BillingAddress.P21Id,
            BillingAddress_Name: checkoutDto.BillingAddress.Name || "",
            BillingAddress_FirstName: checkoutDto.BillingAddress.FirstName || "",
            BillingAddress_LastName: checkoutDto.BillingAddress.LastName || "",
            BillingAddress_Address1: checkoutDto.BillingAddress.Address1 || "",
            BillingAddress_Address2: checkoutDto.BillingAddress.Address2 || "",
            BillingAddress_City: checkoutDto.BillingAddress.City || "",
            BillingAddress_State: checkoutDto.BillingAddress.State || "",
            BillingAddress_Zip: checkoutDto.BillingAddress.Zip || "",
            BillingAddress_Country: checkoutDto.BillingAddress.Country || 'US',
            BillingAddress_Phone: checkoutDto.BillingAddress.Phone || "",
            BillingAddress_PhoneExt: checkoutDto.BillingAddress.PhoneExt || "",
            //EndBillingAddress
            //EmailSettings
            CcEmails: checkoutDto.EmailSettings.CcEmails || [],
            EmailNotes: checkoutDto.EmailSettings.EmailNotes || "",
            ImagesOnQuote: checkoutDto.EmailSettings.ImagesOnQuote,
            SendToShipTo: checkoutDto.EmailSettings.SendToShipTo,
            //EndEmailSettings
            IsQuote: checkoutDto.IsQuote,
            IsCreateAccount: checkoutDto.IsCreateAccount,
            IsTaxExempt: checkoutDto.IsTaxExempt,
            Notes: checkoutDto.Notes || "",
            PaymentOption: checkoutDto.PaymentOption || 'CreditCard',
            PoNumberOrJobName: checkoutDto.PoNumberOrJobName || '',
            QuoteReferenceNumber: checkoutDto.QuoteReferenceNumber,
            QuoteExpirationDate: checkoutDto.QuoteExpirationDate ? new Date(checkoutDto.QuoteExpirationDate) : null,
            IsBillingSameAsShipping: checkoutDto.IsBillingSameAsShipping,
			IsDuplicatePoOverride: checkoutDto.IsDuplicatePoOverride,
            //SelectedContact
            SelectedContact_P21Id: checkoutDto.SelectedContact.P21Id,
            SelectedContact_FirstName: checkoutDto.SelectedContact.FirstName || "",
            SelectedContact_LastName: checkoutDto.SelectedContact.LastName || "",
            SelectedContact_Title: checkoutDto.SelectedContact.Title || "",
            SelectedContact_Phone: checkoutDto.SelectedContact.Phone || "",
            SelectedContact_PhoneExt: checkoutDto.SelectedContact.PhoneExt || "",
            SelectedContact_Email: checkoutDto.SelectedContact.Email || "",
            //EndSelectedContact
            //ShippingChoices
            ShippingChoices_SelectedShippingCarrier: checkoutDto.ShippingChoices.SelectedShippingCarrier,
            ShippingChoices_SelectedCollectCarrier: checkoutDto.ShippingChoices.CollectCarrier,
            ShippingChoices_IsCollect: checkoutDto.ShippingChoices.IsCollect || false,
            ShippingChoices_CollectAccount: checkoutDto.ShippingChoices.CollectAccount || '',
            ShippingChoices_IsRush: checkoutDto.ShippingChoices.IsRush,
            ShippingChoices_NotBeforeDate: (checkoutDto.ShippingChoices.NotBeforeDate ? new Date(checkoutDto.ShippingChoices.NotBeforeDate) : new Date()),
            ShippingChoices_PackingBasis: checkoutDto.ShippingChoices.PackingBasis || 'Complete',
            ShippingChoices_SpecialPaperworkRequest: checkoutDto.ShippingChoices.SpecialPaperworkRequest || 'none',
            ShippingChoices_P21Id: checkoutDto.ShippingChoices.P21Id,
            ShippingChoices_Name: checkoutDto.ShippingChoices.Name || "",
            ShippingChoices_FirstName: checkoutDto.ShippingChoices.FirstName || "",
            ShippingChoices_LastName: checkoutDto.ShippingChoices.LastName || "",
			ShippingChoices_Attn: checkoutDto.ShippingChoices.Attn || "",
            ShippingChoices_Address1: checkoutDto.ShippingChoices.Address1 || "",
            ShippingChoices_Address2: checkoutDto.ShippingChoices.Address2 || "",
            ShippingChoices_City: checkoutDto.ShippingChoices.City || "",
            ShippingChoices_State: checkoutDto.ShippingChoices.State || "",
            ShippingChoices_Zip: checkoutDto.ShippingChoices.Zip || "",
            ShippingChoices_Country: checkoutDto.ShippingChoices.Country || 'US',
            ShippingChoices_Phone: checkoutDto.ShippingChoices.Phone || "",
            ShippingChoices_PhoneExt: checkoutDto.ShippingChoices.PhoneExt || "",
            ShippingChoices_DeliveryInstructions: checkoutDto.ShippingChoices.DeliveryInstructions || "",
            ShippingChoices_ShippingSchedule: checkoutDto.ShippingChoices.ShippingSchedule.map(ss => { return { 
                ...ss,
                RequestedDate: new Date(ss.RequestedDate),
            }}),
            //EndShippingChoices
            //Stripe
            Stripe_StripeCustomerToken: checkoutDto.Stripe.StripeCustomerToken,
            Stripe_StripeCardToken: checkoutDto.Stripe.StripeCardToken,
            Stripe_IsRememberCard: checkoutDto.Stripe.IsRememberCard,
            //EndStripe
        };
        return o;
    }

    //Checkout2InitializeData -> Checkout2Dataset
    const [checkoutInitializeData, setCheckoutInitializeData] = useState({
        BillingInformation: {},
        ShoppingCartToken: null,
        ShippingAddresses: [],
        PackingBasisList: [],
        SpecialPaperworkKinds: [],
        PaymentOptions: [],
        ShoppingCartItems: [],
        OtherInformation: {},
        StripeSystemInfo: {},
    });

    //Initialize Checkout
    function initializeCheckout(createAsQuote) {
        let cartToken = localStorage["shoppingCartToken"] || null;
        setCheckoutInitializeDataIsLoading(true);
        const url = createAsQuote ? `/api/checkout/start/${cartToken}/quote` : `/api/checkout/start/${cartToken}`;

        AirlineFetch(url, Methods.GET)
            .then(async (res) => {
				if(res.ok) {
					return res.json();
				}
				throw new Error((await res.json())?.detail);
			})
            .then((res) => { console.log("INIT res", res); return res; })
            .then((res) => {
                let ncnr = false;
                setCheckoutInitializeData(res.SetupData);
                setCheckoutEditableFields(makeFlat(res.UserEdits));
                setShippingNoRatesAvailable(res.UserEdits.ShippingChoices.IsShippingNoRate);
                res.SetupData.ShoppingCartItems.forEach(element => {
                    if (element.IsNcnr){
                        ncnr = true;
                    }
                });
                setContainNcnrItem(ncnr)
            })
            .catch((err) => {
                console.log("Error initializing checkout", err);
				setAlertContent(<LayoutArea>
					<AreaContentRow>
						<ShowErrorAlert message={err + ""}/>
					</AreaContentRow>   
				</LayoutArea>);
				setShowAlert(true);
				setIsCheckoutInitializeFailed(true);
				setCheckoutInitializeData(null);
            })
            .finally(() => {
                setCheckoutInitializeDataIsLoading(false);
            });
    }

    useEffect(() => {
        if(checkoutInitializeDataIsLoading === false) {
            //Something in the UI changed
            setCheckoutSaveIsLoading(true);
                AirlineFetch("/api/checkout/Save", Methods.POST, makeStructured(checkoutEditableFields))
                    .then((res) => res.json())
                    .then((res) => { 
                        setValidationStatus(res);
                        //Should we clear any current shipping estimates?
                        if(res.ShippingOptionCount === 0 && shippingOptions?.length > 0) {
                            setShippingOptions([]);
                        } 
                    })
                    .finally(() => { setCheckoutSaveIsLoading(false); })
            }
        }
    , [checkoutEditableFieldsDebounced]);

    function getShippingData() {
        setShippingDataIsLoading(true);
        AirlineFetch(`/api/shipping/start/${checkoutEditableFields.CartToken}`, Methods.POST)
            .then((res) => res.json())
            .then((res) => { console.log("ShippingData", res); return res; })
            .then((res) => {
                setShippingGroups(res.ShippingGroups);
                setShippingOptions(res.Options);
                setShippingNoRatesAvailable(res.NoRatesAvailable);
                //If the user clicks get shipping rates, the back-end will clear the selection
                //setCheckoutEditableFields({...checkoutEditableFields, ShippingChoices_SelectedShippingCarrier: null });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setShippingDataIsLoading(false);
            });
    }

    useEffect(() => {
        if(checkoutSaveIsLoading === false && shippingNoRatesAvailable === true) 
        {
            //In a no-rate situation, we have to poke the backend to get a new validation status
            // to continue checkout. Do this automatically by making an appropriate change to the data 
            setCheckoutEditableField('ShippingChoices_SelectedShippingCarrier', null);
        }
    }, [shippingNoRatesAvailable]);

    function setShowBillingAddressEditor(show) {
        //Implements a tracker for the open editor so checkout can not proceed if the editor is open
        setOpenEditorField('billingAddress', show);
        _setShowBillingAddressEditor(show);
    } 

    function setShowShippingAddressEditor(show) {
        //Implements a tracker for the open editor so checkout can not proceed if the editor is open
        setOpenEditorField('shippingAddress', show);
        _setShowShippingAddressEditor(show);
    }

    function setShowShippingOptionsModal(show) {
        //Implements a tracker for the open editor so checkout can not proceed if the editor is open
        setOpenEditorField('shippingOptions', show);
        _setShowShippingOptionsModal(show);
    }   

    function setShowContactEditor(show) {
        //Implements a tracker for the open editor so checkout can not proceed if the editor is open
        setOpenEditorField('contact', show);
        _setShowContactEditor(show);
    }

    function verifyShipToAddress(shipToAddress) {
        setShippingDataIsLoading(true);
        AirlineFetch(`/api/shipping/validateAddress/${checkoutEditableFields.CartToken}`, Methods.POST, shipToAddress)
            .then((res) => res.json())
            .then((res) => { console.log("Verify Addr", res); return res;})
            .then((res) => {
                if(res.status === 500) {
                    //Problem validating the address
                    setAlertContent(
                        <LayoutArea>
                            <AreaContentRow>
                                <ShowErrorAlert message={res.detail}/>
                            </AreaContentRow>   
                        </LayoutArea>
                    );
                    setShowAlert(true);
                    setShippingOptions([]);
					setShowShippingAddressEditor(true); //Pop open the address editor again
                } else {
                    //did anything change?
                    if(((res.Attn || null) !== (shipToAddress.Attn || null))
					|| (res.Address1 !== shipToAddress.Address1) 
                    || (res.Address2 !== shipToAddress.Address2)
                    || (res.City !== shipToAddress.City)
                    || (res.State !== shipToAddress.State)
                    || (res.Zip !== shipToAddress.Zip)
                    || (res.Country !== shipToAddress.Country)
                    ) {
                        setAlertContent(
                            <LayoutArea>
                                <AreaContentRow>
                                    <ShowInfoAlert message="Note: We've updated your address, for accuracy."/>
                                </AreaContentRow>   
                                <AreaContentRow>
                                    <div style={{padding: "5px"}}>
                                        <span>Original Address</span>
                                        <AddressBox address={shipToAddress} hideExtra />
                                    </div>
                                    <div style={{padding: "5px"}}>
                                        <span>Updated Address</span>
                                        <AddressBox address={res} hideExtra highlightDifferencesSource={shipToAddress} />
                                    </div>
                                </AreaContentRow>
                            </LayoutArea>
                        );
                        setShowAlert(true);
                        setShippingOptions([]);
                    }
                
                    setCheckoutEditableFields({...checkoutEditableFields,
                        ShippingChoices_P21Id: res.P21Id,
                        ShippingChoices_Name: res.Name,
                        ShippingChoices_FirstName: res.FirstName,
                        ShippingChoices_LastName: res.LastName,
						ShippingChoices_Attn: res.Attn,
                        ShippingChoices_Address1: res.Address1,
                        ShippingChoices_Address2: res.Address2,
                        ShippingChoices_City: res.City,
                        ShippingChoices_State: res.State,
                        ShippingChoices_Zip: res.Zip,
                        ShippingChoices_Country: res.Country,
                        ShippingChoices_Phone: res.Phone,
                        ShippingChoices_PhoneExt: res.PhoneExt,
                        ShippingChoices_SelectedShippingCarrier: res.PreferredCarrierName,
                        ShippingChoices_IsCollect: res.IsCollect || !!res.CollectAccount,
                        ShippingChoices_CollectAccount: res.CollectAccount || '',
                        ShippingChoices_SelectedCollectCarrier: res.CollectCarrier || res.PreferredCarrierName,
                        ShippingChoices_DeliveryInstructions: res.DeliveryInstructions || '',
                        ShippingChoices_PackingBasis: res.PreferredPackingBasis ? res.PreferredPackingBasis : checkoutEditableFields.ShippingChoices_PackingBasis,
                    });
                }
            })
            .then(() => setShippingDataIsLoading(false))
            .catch((res) => {
                setAlertContent(
                   <LayoutArea>
                       <AreaContentRow>
                           <ShowErrorAlert message="An error occurred while verifying your ship to address."/>
                       </AreaContentRow>   
                       <AreaContentRow>
                           <span>{`${res}`}</span>
                       </AreaContentRow>
                   </LayoutArea>
               );
               setShowAlert(true);
               console.log('error in verifyShipToAddress', res);
           });
    }

    function addPaymentMethod(stripeCardToken) {
        setCheckoutCardsLoading(true);
        AirlineFetch(`/api/checkout/AddPaymentCard/${checkoutEditableFields.CartToken}`, Methods.POST, JSON.stringify(stripeCardToken))
            .then((res) => res.json())
            .then((res) => { console.log("New Payment Methods", res); return res;})
            .then((res) => {
                //Update the init data with the new saved cards
                setCheckoutInitializeData({...checkoutInitializeData,
                    StripeSystemInfo: res
                });
            })
            //Save the card as the selected option
            .then(() => setCheckoutEditableFields({...checkoutEditableFields,
                Stripe_StripeCardToken: stripeCardToken
            }))
            .catch((res) => {
                setAlertContent(
                   <LayoutArea>
                       <AreaContentRow>
                           <ShowErrorAlert message="An error occurred while saving your payment information"/>
                       </AreaContentRow>   
                       <AreaContentRow>
                           <span>{`${res}`}</span>
                       </AreaContentRow>
                   </LayoutArea>
               );
               setShowAlert(true);
               console.log('error in addPaymentMethod', res);
           })
           .finally(() => setCheckoutCardsLoading(false));
    }

    function addPassword(password) {
        setCheckoutSaveIsLoading(true);
        AirlineFetch('/api/checkout/AddPassword/', Methods.POST, JSON.stringify(
            { ShoppingCartToken: checkoutEditableFields.CartToken, 
              UsernameEmail: checkoutEditableFields.SelectedContact_Email, 
              Password: password
            }))
            .then((res) => res.json())
            .then((res) => { console.log("Add Password Results", res); return res;})
            .then((res) => { 
                setValidationStatus(res);
                //Should we clear any current shipping estimates?
                if(res.ShippingOptionCount === 0 && shippingOptions?.length > 0) {
                    setShippingOptions([]);
                } 
            })
            .finally(() => { setCheckoutSaveIsLoading(false); })
    }

    function submitOrder() {
        setCheckoutInitializeDataIsLoading(true);
        AirlineFetch(`/api/checkout/SubmitOrder/${checkoutEditableFields.CartToken}`, Methods.POST, JSON.stringify(isApproveOrder))
            .then((res) => res.json())
            .then((res) => { console.log("Submit Order Results", res); return res;})
            .then((res) => { 
                if(!res.ErrorMessages && !!res.WebReferenceId) {
                    //Do post checkout stuff/clear cart etc
                    //navigate to success page
                    localStorage.removeItem('shoppingCartToken');
                    emptyCart();
                    partnerNotificationOnOrderSubmitCheckout2(res);
                    if (res.CheckoutType === 'quote') {
                        navigate(`/quote-complete/${res.WebReferenceId}`, { state: res });
                    } else {
                        navigate(`/order-complete/${res.WebReferenceId}`, { state: res });
                    }
                } else {
                    setAlertContent(
                        <LayoutArea>
                            <AreaContentRow>
                                <ShowInfoAlert message="We had a problem processing your order."/>
                            </AreaContentRow>   
                            <AreaContentRow>
                                {res.ErrorMessages.map((msg, idx) => { return <span key={idx}>{msg}</span>})}
                            </AreaContentRow>
                        </LayoutArea>
                    );
                    setShowAlert(true);
                }
            }).catch((res) => {
                 setAlertContent(
                    <LayoutArea>
                        <AreaContentRow>
                            <ShowErrorAlert message="An error occurred while submitting your order."/>
                        </AreaContentRow>   
                        <AreaContentRow>
                            <span>{`${res}`}</span>
                        </AreaContentRow>
                    </LayoutArea>
                );
                setShowAlert(true);
                console.log('error in submitOrder', res);
            })
            .finally(() => setCheckoutInitializeDataIsLoading(false));
    }

    return (
        <Provider value={{
			loading,
            initializeCheckout,
            checkoutInitializeData,
            checkoutInitializeDataIsLoading,
			isCheckoutInitializeFailed,
            checkoutEditableFields, 
            setCheckoutEditableFields,
            setCheckoutEditableField,
            checkoutSaveIsLoading,
            isApproveOrder,
            setIsApproveOrder,
            shippingOptions,
            shippingGroups,
            shippingNoRatesAvailable,
            shippingDataIsLoading,
            getShippingData,
            checkoutCardsLoading,
            verifyShipToAddress,
            validationStatus,
            addPaymentMethod,
            showAlert,
            setShowAlert,
            alertContent,
            submitOrder,
            addPassword,
            inPageRefs,
            openEditors,
            setOpenEditorField,
            getFirstOpenEditorInPageRef,
            containNcnrItem,
            setContainNcnrItem,
            acknowledgeNcnr,
            setAcknowledgeNcnr,
			showBillingAddressEditor,
			setShowBillingAddressEditor,
			showShippingAddressEditor,   //The state of the shipping address editor is controllable
			setShowShippingAddressEditor, //  by the verifyShipToAddress function, in case of a failure
			showShippingOptionsModal,
			setShowShippingOptionsModal,
			showContactEditor,
			setShowContactEditor,
        }}>
            {children}
        </Provider>
    )
}
