import gql from 'graphql-tag'
import { FRAGMENT_ITEM_AVAILABILITY } from './gqlFragments/gqlItemFragments'

export const BEGIN_IMPERSONATION = gql`
    query BeginImpersonation ($customerId: Int){
        impersonationBegin(customerId: $customerId){
            success
            message
            authorizationInfo {
                token
                refreshToken
                userInfo {
                    companyId
                    companyName
                    firstName
                    lastName
                    role
                    permissions
                    limits {
                        limitType
                        limitValue
                    }
                }
                impersonationUserInfo {
                    customerId
                    customerName
                    customerIdP21
                    customerMandatoryNotes
                    {
                        note
                        noteId
                    }
                }
            }
        }
    }
`

export const IMPERSONATOR_HISTORY = gql`
    query getImpersonatorHistory {
        getImpersonatorHistory{
            customerName
            customerId
            customerIdP21
            viewCount
        }
    }
`

export const END_IMPERSONATION = gql`
    query EndImpersonation{
        impersonationEnd{
            success
            message
            authorizationInfo{
                token
                refreshToken
                userInfo{
                    companyId
                    companyName
                    firstName
                    lastName
                    role
                    permissions
                    limits{
                    limitType
                    limitValue
                    }
                }
            }
        }
    }
`

//Variables example: {variables: {'searchString': 'blah'}}
export const IMPERSONATION_SEARCH = gql`
    query GetImpersonationCustomerList($searchString: String){
        getImpersonationCustomerList(searchString: $searchString){
            customerIdP21
            name
            id
        }
    }
`

export const GET_TAX_RATE = gql`
    query GetTaxRate($taxRateRequest: TaxRateRequest) {
        getTaxRate(taxRateRequest: $taxRateRequest)
    }
`

export const SUBMIT_ORDER = gql`
    mutation SubmitOrder($order: OrderInputDataInputGraphType){
        submitOrder(orderInput: $order){
            webReferenceId
            errorMessages
            checkoutType
            affiliateName
            itemsSubTotal
            taxTotal
            tariffTotal
            shippingCost
            grandTotal
            cartItems {
                invMastUid
                itemCode
                brand
                unitPrice
                quantity
                imageUrl,
                description,
                unitOfMeasure,
                utmSource
                utmMedium
                utmCampaign
            }
        }
    }
`
export const QUOTE_TO_ORDER =gql`
    mutation MakeOrderQuote($targetQuote: Int, $poNumber: String){
        makeOrderQuote(targetQuote: $targetQuote, poNumber: $poNumber)
    }
`
export const QUICK_PAYMENT_METHOD =gql`
    mutation QuickPaymentMethod($poNumber: String, $paymentSystemMethodId: String){
        quickPaymentMethod(poNumber: $poNumber, paymentSystemMethodId: $paymentSystemMethodId)
    }
`

export const GET_ITEM_BY_ID = gql`
    query ItemById($itemId: Int){
        itemDetails(invMastUid: $itemId) {
            invMastUid
            itemCode
            itemCodeUrlSanitized
            itemDesc
            mfgPartNo
            modelCode
            tariff
            unitSizeMultiple
            availability
            supplierId
            isNcnr
            itemMedia {
                path
                sequence
                itemMediaType
                mediaType
                altText
                pixelWidth
                pixelHeight
            }
            restrictedQty
            restrictedCustomers {
                name
                customerIdP21
            }
        }
        customerPartNumbers(invMastUid: $itemId){
            customerPartNumber
            id
        }
    }
`

export const GET_ITEMS_BY_ID = gql`
    query GetItemDetails($invMastUids: [Int]){
        itemDetailsBatch(invMastUids: $invMastUids){
            invMastUid
            itemCode
            itemCodeUrlSanitized
            itemDesc
            extendedDesc
            mfgPartNo
            nsn
            modelCode
            tariff
            unitSizeMultiple
            availability
            supplierId
            isNcnr
            isPreferredStock
            itemFamilies {
                familyInvMastUid
                members {
                    relationshipToFamily
                    invMastUid
                }
            }
            itemMedia {
                path
                sequence
                mediaType
                itemMediaType
                altText
                pixelWidth
                pixelHeight
            }
            restrictedQty
            restrictedCustomers {
                name
                customerIdP21
            }
        }
    }
`
export const CART_IMPORT_DEFINITION = gql`
    query GetCartImportDefinition {
        shoppingCartImportDefinition 
    }
`

//Shopping Cart Import returns the same structure as Shopping Cart Update
export const CART_IMPORT = gql`
    mutation ImportCart($import: ShoppingCartImportGraphType){
        shoppingCartImport(import: $import) {
            token
            attachments {
                fileId
                fileName
            }
            cartItems{
                uniqueId
                invMastUid
                customerPartNumberId
                quantity
                quoteLineQuantity
                quoteUnitPrice
                isQuoteLineActive
                quoteLineId
                pricePage
                itemTotalTariff
                itemUnitPrice 
                itemUnitPriceOverride
                airlineCost
                priceReasonId
                sourceLocId
                disposition
                promiseDate
                promiseDateOverride
                isDropship
                purchaseOrderCost
                supplierId
                extraNotes {
                    note
                    targetAreas
                }
            }
            subtotal
            tariff
            orderNotes
            action
            importResponse {
                overallSuccess
                lineImportErrors
            }
        }
    }
`

export const UPDATE_CART = gql`
    mutation UpdateCart($cartInfo: ShoppingCartUpdate){
        shoppingCart(cartUpdate: $cartInfo){
            token
            attachments {
                fileId
                fileName
            }
            cartItems{
                uniqueId
                invMastUid
                customerPartNumberId
                quantity
                quoteLineQuantity
                quoteUnitPrice
                isQuoteLineActive
                quoteLineId
                pricePage
                itemTotalTariff
                itemUnitPrice
                itemUnitPriceOverride
                airlineCost
                priceReasonId
                sourceLocId
                disposition
                promiseDate
                promiseDateOverride
                isDropship
                purchaseOrderCost
                supplierId
                extraNotes {
                    note
                    targetAreas
                }
                customizations {
                    axis
                    dimLengthX
                    materialItemInvMastUid
                    customizationKind
                    unitOfMeasure
                    customizedItem {
                        invMastUid
                    }
                }
            }
            subtotal
            tariff
            orderNotes
            action
            noteAreas
        }
    }
`

export const ADD_CATALOG_ITEM = gql`
    mutation CatalogItemAdd($catalogItem: ShoppingCartCatalogItemAdd){
        shoppingCartAddCatalogItem(catalogItem: $catalogItem){
            token
            cartItems{
                uniqueId
                invMastUid
                customerPartNumberId
                quantity
                quoteLineQuantity
                quoteUnitPrice
                isQuoteLineActive
                quoteLineId
                itemTotalTariff
                itemUnitPrice
                itemUnitPriceOverride
                airlineCost
                priceReasonId
                sourceLocId
                disposition
                promiseDate
                promiseDateOverride
                isDropship
                extraNotes {
                    note
                    targetAreas
                }
            }
            subtotal
            tariff
            orderNotes
            action
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($changePasswordInfo: ChangePasswordGraphType) {
        changePassword(changePasswordInfo: $changePasswordInfo) {
            success
            message
        }
    }
`

export const QUERY_LOGIN = gql`
    query SubmitLogin($loginInfo: LoginInputGraphType){
        submitLogin(login: $loginInfo){
            success
            message
            isPasswordReset
            authorizationInfo{
                token
                refreshToken
                userInfo {
                    firstName
                    lastName
                    companyName
                    companyId
                    role
                    webUserId
                    permissions
                    limits {
                        limitType
                        limitValue
                    }
                }
            }
        }
    }
`

export const GET_ORDERS = gql`
    query getOrders($orderStatus: String, $dateFrom: Date, $dateTo: Date, $numPerPage: Int, $pageIndex: Int, $filter: String, $shipToIdFilter: Int) {
        accountOrders(orderStatus: $orderStatus, dateFrom: $dateFrom, dateTo: $dateTo, numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter, shipToIdFilter: $shipToIdFilter) {
            totalCount
            orders {
                orderNumber
                orderDate
                poNo
                buyer
                itemsTotal
                isQuote
                status
                isMine
                quoteRefNo
                promiseDateOrTBD
                jobName
                webReferenceNumber
            }
        }
    }
`

export const GET_QUOTES = gql`
    query getQuotes($quoteStatus: String, $dateFrom: Date, $dateTo: Date, $numPerPage: Int, $pageIndex: Int, $filter: String) {
        accountQuotes(quoteStatus: $quoteStatus, dateFrom: $dateFrom, dateTo: $dateTo, numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter) {
            totalCount
            quotes {
                quoteNumber
                isMine
                itemsTotal
                promiseDateOrTBD
                quoteDate
                quoteReferenceNumber
                status
                webReferenceNumber
            }
        }
    }
`

export const GET_ORDERS_DETAIL = gql`
    query AccountOrderDetails($orderNumber: String){
        accountOrderDetails(orderNumber: $orderNumber){
            orderNumber
            orderDate
            poNo
            buyer
            total
            isQuote
            orderType
            status
            packingBasis
            promiseDateOrTBD
            isMine
            shipToName
            shipToAddress1
            shipToAddress2
            shipToAddress3
            shipToCity
            shipToState
            shipToZip
            shipToCountry
            quoteRefNo
            webReferenceNumber
            orderNotes
            billToCustomerIdP21
            tax
            taxable
            totalShipping
            quoteHeader {
                quoteId
                isCompleted
                expirationDate
            }
            lineItems {
                lineItemId
                orderNumber
                lineNumber
                lineSeqNo
                invMastUid
                itemCode
                customerPartNumber
                customerPartNumberId
                quantityOrdered
                quantityOpen
                quantityInvoiced
                unitOfMeasure
                unitPrice
                totalPrice
                quoteLineId
                promiseDateOrTBD
                aroDays
                isCancelled
                quantityAllocated
                tax
                extendedDesc
                itemNotes{
                    note	
                    targetAreas
                }
                extraNotes{
                    note
                    targetAreas
                }
                trackingNumbers{
                    carrierId
                    carrierName
                    trackingNumber
                    trackingUrl
                }
            }
        }
    }
`

export const GET_ANONYMOUS_ORDERS_DETAIL = gql`
    query AnonymousAccountOrderDetails($orderNumber: String, $email: String){
        anonymousOrderDetails(orderNumber: $orderNumber, email: $email){
            orderNumber
            orderDate
            poNo
            buyer
            total
            isQuote
            orderType
            status
            packingBasis
            promiseDateOrTBD
            isMine
            shipToName
            shipToAddress1
            shipToAddress2
            shipToAddress3
            shipToCity
            shipToState
            shipToZip
            shipToCountry
            shipToPhone
            billToCustomerIdP21
            billToName
            billToCompanyName
            billToAddress1
            billToAddress2
            billToAddress3
            billToCity
            billToState
            billToZip
            billToCountry
            billToPhone
            tax
            deliveryInstructions
            quoteRefNo
            webReferenceNumber
            orderNotes
            quoteHeader {
                quoteId
                isCompleted
                expirationDate
            }
            lineItems {
                lineItemId
                orderNumber
                lineNumber
                invMastUid
                itemCode
                customerPartNumber
                customerPartNumberId
                quantityOrdered
                quantityOpen
                quantityInvoiced
                unitOfMeasure
                unitPrice
                totalPrice
                quoteLineId
                promiseDateOrTBD
                aroDays
                isCancelled
                quantityAllocated
                itemNotes{
                    note	
                    targetAreas
                }
                trackingNumbers{
                    carrierId
                    carrierName
                    trackingNumber
                    trackingUrl
                }
            }
        }
    }
`

export const GET_ORDER_TOTAL = gql`
    query AccountOrderDetails($orderNumber: String){
        accountOrderDetails(orderNumber: $orderNumber){
            total
        }
    }
`

export const GET_INVOICES = gql`
    query getInvoices($invoiceStatus: String, $dateFrom: Date, $dateTo: Date, $numPerPage: Int, $pageIndex: Int, $filter: String){
        accountInvoices(invoiceStatus: $invoiceStatus, dateFrom: $dateFrom, dateTo: $dateTo, numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter){
            totalCount
            invoices {
                invoiceNumber
                orderNumber
                invoiceDate
                termsDueDate
                netDueDate
                poNo
                status
                amountDue
                amountPaid
                isMine
                lineItems {
                    invMastUid
                    itemCode
                    customerPartNumber
                    customerPartNumberId
                }
            }
        }
    }
`

export const GET_ANONYMOUS_INVOICE = gql`
    query InvoiceByOrder($orderNumber: String, $email: String){
        anonymousInvoiceDetail(orderNumber: $orderNumber, email: $email){
            invoiceNumber
            orderNumber
            orderDate
            invoiceDate
            termsDueDate
            netDueDate
            discDueDate
            orderedBy
            taker
            poNo
            totalAmount
            freightAmount
            status
            discountAmount
            amountPaid        
            terms
            shipToName
            shipToAddress1
            shipToAddress2
            shipToAddress3
            shipToCity
            shipToState
            shipToZip
            shipToCountry
            deliveryInstructions
            invoiceNotes
            billingName
            billingAddress1
            billingAddress2
            billingAddress3
            billingCity
            billingState
            billingZip
            billingCountry
            subTotal
            totalTax
            amountDue
            lineItems {
                invoiceLineId
                orderNumber
                isTaxItem
                isOtherItem
                invoiceLineType
                invoiceLineNumber
                orderLineNumber
                quantityRequested
                quantityShipped
                pricingQuantity
                invMastUid
                itemCode
                itemDescription
                customerPartNumber
                customerPartNumberId
                unitPrice
                itemTotalPrice
                trackingNumbers {
                    trackingNumber
                    carrierId
                    carrierName
                    trackingUrl
                }
            }
        }
    }
`

export const GET_INVOICE = gql`
    query AccountInvoiceDetail($invoiceNumber: String){
        accountInvoiceDetail(invoiceNumber: $invoiceNumber){
            invoiceNumber
            orderNumber
            orderDate
            invoiceDate
            termsDueDate
            netDueDate
            discDueDate
            orderedBy
            taker
            poNo
            totalAmount
            freightAmount
            status
            discountAmount
            amountPaid
            isMine
            terms
            shipToName
            shipToAddress1
            shipToAddress2
            shipToAddress3
            shipToCity
            shipToState
            shipToZip
            shipToCountry
            deliveryInstructions
            invoiceNotes
            billingName
            billingAddress1
            billingAddress2
            billingAddress3
            billingCity
            billingState
            billingZip
            billingCountry
            subTotal
            totalTax
            amountDue
            lineItems {
                invoiceLineId
                orderNumber
                isTaxItem
                isOtherItem
                invoiceLineType
                invoiceLineNumber
                orderLineNumber
                quantityRequested
                quantityShipped
                pricingQuantity
                invMastUid
                itemCode
                itemDescription
                customerPartNumber
                customerPartNumberId
                unitPrice
                itemTotalPrice
                lineNotes
                trackingNumbers {
                    trackingNumber
                    carrierId
                    carrierName
                    trackingUrl
                }
            }
        }
    }
`

export const GET_PURCHASE_HISTORY = gql`
    query GetPurchaseHistory($dateFrom: Date, $dateTo: Date, $numPerPage: Int, $pageIndex: Int, $filter: String) {
        purchaseHistory(dateFrom: $dateFrom, dateTo: $dateTo, numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter) {
            totalCount
            items {
                invMastUid
                itemId
                customerPartNumber
                lastDateOrdered
                lastQuantityPurchased
                totalQuantityPurchased
                quantityAvailable
                numberTimesOrdered
                leadTimeDays
                unitOfMeasure
                itemImageUrl
                isAssembly
                webItemDescription
                associatedOrderDetails {
                    orderNumber
                    pONumber
                    webReferenceNumber
                }
            }
        }
    }
`

export const GET_ITEM_PRICE = gql`
    query GetItemPrices($items: [ItemQuantityInput]) {
        getItemPrices(items: $items) {
            invMastUid
            quantity
            totalPrice
            unitPrice
            unitOfMeasure
            spaNumber
            spaCost
            spaMargin
            roundType
            unitSize
            spaType
            listPrice
        }
    }
`

export const GET_ITEM_AVAILABILITY = gql`
    query GetItemAvailability($invMastUids: [Int]){
        itemAvailability(invMastUids: $invMastUids) {
            ...ItemAvailability
        }
    }
    ${FRAGMENT_ITEM_AVAILABILITY}
`

export const GET_ITEM_AVAILABILITIES_AND_LEAD_TIMES = gql`
    query GetCartItemAvailabilityAndLeadTimes($itemsAndQuantities: [ItemAndQuantityInput]){
        itemAvailabilityAndLeadTimes(itemsAndQuantities: $itemsAndQuantities) {
            ...ItemAvailability
        }
    }
    ${FRAGMENT_ITEM_AVAILABILITY}
`

export const GET_SHOPPING_LISTS = gql`
    query GetShoppingLists {
        shoppingList {
            id
            isForAllCustomer
            ownerId
            ownerName
            name
            notes
            editors {
                webUserId
                firstName
                lastName
            }
            shoppingListItems {
                invMastUid
                itemCode
                quantity
                customerPartNumber
                customerPartNumberId
                itemDescription
                imageUrl
                isAssembly
            }
        }
    }
`

export const UPDATE_SHOPPING_LISTS = gql`
    mutation ShoppingListUpdate($shoppingList: ShoppingListInput) {
        shoppingListEdit(shoppingList: $shoppingList) {
            id
            isForAllCustomer
            name
            notes
            deleted
            editors {
                webUserId
                firstName
                lastName
            }
            shoppingListItems {
                invMastUid
                itemCode
                quantity
                customerPartNumber
                customerPartNumberId
                itemDescription
                imageUrl
            }
        }
    }
`

export const GET_WEB_USER_CONTACTS = gql`
    query GetWebUserContacts {
        webUsers {
            webUserId
            firstName
            lastName
        }
    }
`

export const QUERY_ITEM_SEARCH = gql`
    query Search($search: SearchRequestInput) {
        itemSearch(searchParams: $search) {
            searchTerm
            searchType
            innerSearchTerms
            sortType
            resultPage
            resultSize
            searchTotalCount
            selectedCategory {
                categoryId
                categoryName
                categoryDisplayName
                categoryCount
            }
            childCategories {
                categoryId
                categoryName
                categoryDisplayName
                categoryCount
            }
            searchState {
                brands {
                    brandName
                    brandNameDisplay
                    brandCount
                    selected
                }
                attributes {
                    attributeName
                    attributeNameDisplay
                    features {
                        featureName
                        featureNameDisplay
                        featureCount
                        selected
                    }
                }
            }
            result
        }
    }
`
export const OPENORDERS = gql`
    query OpenOrders($customerId: Int, $pageSize: Int, $pageNumber: Int, $filterText: String, $startDate: Date, $endDate: Date, $shipToIdFilter: String){
        openOrders(customerId: $customerId, pageSize: $pageSize, pageNumber: $pageNumber, filterText: $filterText, startDate: $startDate, endDate: $endDate, shipToIdFilter: $shipToIdFilter){
            count
            data {
                orderDate
                orderNum
                pONum
                webReferenceNum
                promiseDateOrTBD
                itemID
                invMastUid
                customerPart
                webItemDesc
                qtyOrdered
                qtyAllocated
                qtyOpen
                unitPrice
                totalPrice
                jobName
                taker
            }
        }
    }
`

export const GET_DOWNPAYMENT_ORDERS_OPTIONS = gql`
    query OpenOrders($customerId: Int){
        openOrders(customerId: $customerId, pageSize: 10000, pageNumber: 0){
            count
            data {
                orderNum
            }
        }
    }
`

export const CATEGORY_SEARCH = gql`
    query CategorySearch($searchParams: CategorySearchRequest){
    categorySearch(searchParams: $searchParams){
        category {
            id
            name
            urlSlug
            imageUrl
            seoHtml
            hasBeenRedirected
            lastModifiedDate
            breadCrumbs {
                breadCrumbUrl
                breadcrumbTrail {
                    id
                    name
                    urlSlug
                    imageUrl
                    seoHtml
                }
            }
            children {
                id
                name
                urlSlug
                imageUrl
                seoHtml
                breadCrumbs {
                    breadCrumbUrl
                    breadcrumbTrail {
                        id
                        name
                        urlSlug
                        imageUrl
                        seoHtml
                    }
                }
            }
        }
        attributes {
            attributeName
            attributeNameDisplay
            features {
                featureName
                featureNameDisplay
                featureCount
                selected
            }
        }
        brands {
            brandName
            brandNameDisplay
            brandCount
            selected
        }
        innerSearchTerms
        resultPage
        resultSize
        searchType
        sortType
        searchTotalCount
        result
        }
    }
`

export const QUERY_STOCK_AVAILABILITY = gql`
    query GetStockAvailability($invMastUid: Int){
        airlineStock(invMastUid: $invMastUid) {
            invMastUid
            itemCode
            itemCodeUrlSanitized
            companyId
            locationId
            locationName
            quantityAvailable
            quantityAvailableToMake
        }
        factoryStock(invMastUid: $invMastUid) {
            invMastUid
            factoryAvailability
            leadTimeDays
            factoryMessage
            modifiedBy
            modifiedDate
        }
        onOrder(invMastUid: $invMastUid) {
            invMastUid
            receiptQtyDueIn
            isBeingWatched
        }
    }
`

export const QUERY_STOCK_AVAILABILITY_BATCH = gql`
    query GetStockAvailabilityBatch($invMastUids: [Int]){
        airlineStockBatch(invMastUids: $invMastUids) {
            invMastUid
            itemCode
            itemCodeUrlSanitized
            companyId
            locationId
            locationName
            quantityAvailable
            quantityAvailableToMake
        }
        factoryStockBatch(invMastUids: $invMastUids) {
            invMastUid
            factoryAvailability
            leadTimeDays
            factoryMessage
            modifiedBy
            modifiedDate
        }
        onOrderBatch(invMastUids: $invMastUids) {
            invMastUid
            receiptQtyDueIn
            isBeingWatched
        }
    }
`

export const SAVE_NEW_CUSTOMER = gql`
    mutation SaveRegistration($reg: NewCustomerRegistrationInputGraphType) {
        saveRegistration(reg: $reg)
    }
`

//Get a list of new customers that have not been processed yet (form submitted, but customers & logins not created)
export const GET_NEW_CUSTOMERS = gql`
    query newCustomers($includeCompleted: Boolean, $numPerPage: Int, $pageIndex: Int, $filter: String ) {
        newCustomers(includeCompleted: $includeCompleted, numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter) {
            totalCount
            registrations {
                id
                customerIdP21
                received
                email
                fax
                firstName
                jobTitle
                lastName
                phone
                phoneExtension
                billingCity
                billingCompanyName
                billingCountry
                billingLine1
                billingLine2
                billingState
                billingZip
                shippingCity
                shippingCompanyName
                shippingCountry
                shippingLine1
                shippingLine2
                shippingState
                shippingZip
                rejectReason
                regStatus
            }
        }
    }
`

export const GET_NEW_CUSTOMER = gql`
    query getNewCustomer($id: Int) {
        newCustomer(id: $id) {
            id
            customerIdP21
            email
            fax
            firstName
            jobTitle
            lastName
            phone
            phoneExtension
            billingCity
            billingCompanyName
            billingCountry
            billingLine1
            billingLine2
            billingState
            billingZip
            shippingCity
            shippingCompanyName
            shippingCountry
            shippingLine1
            shippingLine2
            shippingState
            shippingZip
            rejectReason
            regStatus
        }
    }
`

//Pass a registrationCustomerID (from GET_NEW_CUSTOMERS) to reject the account request. It will
// not be available in subsequent GET_NEW_CUSTOMERS requests.
//Variables: { "id": 19 }
export const REJECT_NEW_CUSTOMER = gql`
    mutation rejectReg($id: Int, $reason: String) {
        rejectRegistration(id: $id, reason: $reason)
    }
`
//Pass a registrationCustomerID to import the account into (or associate an existing account in) P21 and
// create a login record from the associated contact
//Variables: { "id": 18 }
export const APPROVE_NEW_CUSTOMER = gql`
    mutation approveReg($id: Int) {
        approveRegistration(id: $id)
    }
`

//Pass a registrationCustomerID to delete the registration record entirely
//Variables: { "id": 18 }
export const DELETE_NEW_CUSTOMER = gql`
    mutation deleteReg($id: Int) {
        deleteRegistration(id: $id)
    }
`
export const GET_ALL_SETTINGS = gql`
    query appSettings {
        appSettings {
            newCustomerNotificationEmails
            contactUsNotificationEmails
            emailFrom
            siteBaseUrl
            adminDashNewCustomersRelativeUrl
            orderConfirmationEmailRecipients
            priceRequestEmailRecipients
            blockedEmailDomains
        }
    }
`

export const SAVE_ALL_SETTINGS = gql`
    mutation saveAppSettings($settings: saveAppSettings) {
        saveAppSettings(settings: $settings)
    }
`

export const GET_CHECKOUT_DATA_SLIM = gql`
    query RetrieveCheckoutData {
        getCheckoutDropdownData {
            billingInfo {
                requiresPONumber
                terms
                isPoAllowed
            }
        }
    }

`

export const GET_CHECKOUT_DATA = gql`
    query RetrieveCheckoutData {
        getCheckoutDropdownData {
            shipToAddresses {
                id
                name
                companyName
                physAddress1
                physAddress2
                physAddress3
                physCity
                physState
                physPostalCode
                physCountry
                collectNumberUps
                carrierId
                shippingNote
                shipToPackingBasis
            }
            carriers {
                id
                name
            }
            contacts {
                id
                firstName
                lastName
                phoneNumber
                phoneExt
                email
                title
            }
            billingInfo {
                requiresPONumber
                terms
                isPoAllowed
                address1
                address2
                city
                state
                zip
                country
                companyName
            }
        }
    }
`
export const GET_SHIP_TO_FILTER = gql`
    query ShipToFilter {
        getCheckoutDropdownData {
            shipToAddresses {
                id
                name
                companyName
                physAddress1
                physCity
                physState
                physPostalCode
                physCountry
            }
        }
    }
`

export const GET_PAYMENT_METHOD_INFO = gql`
    query GetPaymentMethodInfo ($paymentMethodRequest: PaymentMethodInfoRetrieve){
        paymentMethodInfo(paymentMethodInfo: $paymentMethodRequest) {
            paymentSystemSecretKey
            paymentSystemCustomerId
            paymentMethods {
                paymentMethodId
                paymentSystemCustomerId
                type
                card {
                    brand
                    expirationMonth
                    expirationYear
                    lastFour
                }
            }
        }
    }
`

export const SAVE_PAYMENT_METHOD = gql`
    mutation SavePaymentMethod ($paymentSystemMethodId: String!, $paymentSystemCustomerId: String!) {
        savePaymentMethod(
            paymentSystemMethodId: $paymentSystemMethodId,
            paymentSystemCustomerId: $paymentSystemCustomerId
        ) {
            paymentMethods {
                paymentMethodId
                paymentSystemCustomerId
                card {
                    brand
                    expirationMonth
                    expirationYear
                    lastFour
                }
            }
        }  
    }
`

export const REMOVE_SAVED_PAYMENT_METHOD = gql`
    mutation RemoveSavedPaymentMethod ($paymentSystemMethodId: String!) {
        removeSavedPaymentMethod(
            paymentSystemMethodId: $paymentSystemMethodId
        )
    }
`

export const UPDATE_SAVED_PAYMENT_METHOD = gql`
    mutation UpdateSavedPaymentMethod (
        $paymentSystemMethodId: String!
        $month: Int!
        $year: Int!
        $zip: String!
    ) {
        updateSavedPaymentMethod(
            paymentSystemMethodId: $paymentSystemMethodId
            month: $month
            year: $year
            zip: $zip
        )
    }
`

export const GET_ROOT_CATEGORIES_HOMEPAGE = gql`
    query GetRootCategoriesHomepage {
        getAllRootCategories {
            id
            name
            urlSlug
            imageUrl
        }
    }
`

export const GET_ROOT_CATEGORIES_HEADER = gql`
{
    getAllRootCategories {
        id
        name
        urlSlug
        imageUrl
        seoHtml
    }
}
`

export const GET_ROOT_CATEGORIES_PAGE = gql`
{
    getAllRootCategories {
        id
        name
        urlSlug
        imageUrl
        seoHtml
    }
}
`

export const GET_ALL_USER_CARTS = gql`
    query GetAllEmployeeCarts {
        employeeCarts {
            customerId
            customerName
            customerIdP21
            shoppingCartItemCount
        }
    }
`

export const MERGE_CART_TO_CUSTOMER = gql`
    mutation copyShoppingCartToCustomer($customerId: Int){
        copyShoppingCartToCustomer(customerId: $customerId) {
            customerId
            cartItems {
                invMastUid
            }
        }
    }
`

export const GET_PRICE_REASONS = gql`
    query GetPriceReasons {
        priceReasons {
            id
            priceReason
        }
    }
`

export const ADD_PREPAYMENT = gql`
    mutation a($thing : PrepaymentModel) {
        addPrepayment(prepayment: $thing) {
            amountToCharge
            orderNumber
            paymentSystemCustomerId
            paymentMethodId
            amountCharged
            status
            id
            createdDate
            processingInfo
            receiptCode
        }
    }
`

export const GET_PREPAYMENTS = gql`
    query getRemittances($startDate: DateTime) {
        getRemittances(startDate: $startDate) {
            amountCharged
            amountToCharge
            createdDate
            id
            orderNumber
            paymentMethodId
            paymentSystemCustomerId
            processingInfo
            receiptCode
            status
        }
    } 
`

export const ADVANCED_IMPERSONATION_SEARCH = gql`
    query ImpersonationCustomerAdvancedSearch(
        $searchType: String, 
        $searchString: String,
        $customerId: String,
        $customerName: String,
        $customerType: String,
        $customerTerms: String,
        $address1: String,
        $city: String,
        $stateCode: String,
        $postalCode: String,
        $contactFirstName: String,
        $contactLastName: String
    ) {
    customerImpersonateAdvancedSearch(
        searchType: $searchType, 
        searchString: $searchString,
        customerId: $customerId,
        customerName: $customerName,
        customerType: $customerType,
        customerTerms: $customerTerms,
        address1: $address1,
        city: $city,
        stateCode: $stateCode,
        postalCode: $postalCode,
        contactFirstName: $contactFirstName,
        contactLastName: $contactLastName) {
            customerIdP21
            customerAirlineId
            customerName
            customerType
            customerTerms
            resultString
        }
    }
`

export const GET_HOMEPAGE = gql`
    query getMarketingData {
        getMarketingData {
            html
            href
            imageUrl
            key
            sectionName
            sort
            title
        }
    }
`
export const GET_BRANDEDITOR = gql`
    query getBrandData ($urlSlug: String) {
        getBrandData (urlSlug: $urlSlug){
            key
            sort
            value
        }
    }
`

export const GET_AUTHENTICATION_HEARTBEAT = gql`
    mutation AuthenticationHeartbeat {
        authenticationHeartbeat
    }
`

export const CHECK_PO = gql`
    query IsDuplicatePoNumber($poNumber: String) {
        isDuplicatePurchaseOrderNumber(purchaseOrderNumber: $poNumber)
    }
`

export const GET_SUPPLIERS = gql`
    query allSuppliers {
        allSuppliers {
            id
            name
        }
    }
`

export const Get_CUSTOMERPARTNUMBERNOTE =gql`
    query customerPartNumbersNote($invMastUids: [Int]) {
        customerPartNumbersNote (invMastUids: $invMastUids){
            topic
            invMastUid
            note
        }
    }
`

export const GET_BASICTRACKINGNUMBERS = gql`
    query getBasicTrackingNumbers($orderNo: String) {
        getBasicTrackingNumbers (orderNo: $orderNo){
            orderNumber
            lineNumber
            trackingNo
        }
    }
`

export const GET_ALERTS = gql`
    query getWebsiteAlert {
        websiteAlert {
            style
            noteHtml
            endDate
        }
    }
`

export const GET_ASSEMBLY_DETAILS = gql`
    query ($invMastUid: Int) {
        assemblyInformation(inv_mast_uid: $invMastUid) {
            componentInvMastUid
            componentItemId
            componentQtyOnHand
            componentQtyAllocated
            componentQtyOnPo
            componentQtyReceivedOnPo
            componentQuantity
            componentUnitOfMeasure
        }
        assemblyParentInformation(inv_mast_uid: $invMastUid) {
            supplierId
            supplierName
            prefLocation
        }
    }
`

export const GET_RELATIONS = gql`
    query ($invMastUid: Int) {
        relatedTo(invMastUid: $invMastUid) {
            itemCode
            invMastUid
            details
            itemCodeUrlSanitized
            type
            isAssembly
            isNcnr
            itemMedia {
                path
                sequence
                itemMediaType
                mediaType
                mediaId
                altText
                pixelWidth
                pixelHeight
            }
        }
    }
`
export const GET_BILLING_AND_SHIPPING = gql`
    query GetBillingAndShippingData{
        getBillingAndShippingData{
            shipToAddresses{
                address1
                address2
                address3
                city
                country
                emailAddress
                name
                state
                postalCode
                awPackingBasis
                defaultCarrierName
                freightCode
            }
            billToAddresses{
                address1
                address2
                address3
                city
                country
                emailAddress
                name
                state
                postalCode
            }
        }
    }
`
export const GET_MY_PRODUCTS = gql`
    query myProducts($numPerPage: Int, $pageIndex: Int, $filter: String, $sortColumn: String, $sortDirection: String) {
        myProducts(numPerPage: $numPerPage, pageIndex: $pageIndex, filter: $filter, sortColumn: $sortColumn, sortDirection: $sortDirection )
        {
            products {
                invMastUid
                itemId
                customerPartNumber
                itemDescription
                currentPrice
                brandName
                brandMedia{
                    imageHeight
                    imageWidth
                    imagePath
                    svgAspectRatio
                    svgPath
                }
                itemMedia{
                    mediaId,
                    path
                    itemMediaType
                    mediaType
                    sequence
                    altText
                    pixelWidth
                    pixelHeight
                }
            }
            totalCount
        }
    }
`
export const GET_MANUFACTURER_CATALOG = gql`
    query manufacturerCatalog($brandId: Int) {
        manufacturerCatalog(brandId: $brandId){
            brandId
            path
            description
        }
    }
`
export const GET_MANUFACTURER_CATALOG_SEARCH = gql`
    query manufacturerCatalogSearch($search: String) {
        manufacturerCatalogSearch(search: $search){
            brandId
            path
            description
        }
    }
`

export const GET_BRAND_INFO = gql`
    query getBrands{
       getBrands{
        id
        name
       }
    }
`

export const GET_FREQUENTLY_BOUGHT_TOGETHER = gql`
    query ($invMastUid: Int) {
        frequentlyBoughtTogether(invMastUid: $invMastUid) {
            itemCode
            invMastUid
            details
            itemCodeUrlSanitized
            type
            isAssembly
            isNcnr
            itemDesc
            itemMedia {
                path
                sequence
                itemMediaType
                mediaType
                mediaId
                altText
                pixelWidth
                pixelHeight
            }
        }
    }
`

export const GET_FREQUENT_ITEMS_INFO = gql`
    query GetFrequentItems($items: [ItemQuantityInput], $invMastUids: [Int]) {
        getItemPrices(items: $items) {
            invMastUid
            quantity
            unitPrice
            unitOfMeasure
            roundType
            unitSize
        }
		itemAvailability(invMastUids: $invMastUids){
            invMastUid
            availability
            leadTimeDays
		}
    }
`

export const REMOVE_CUSTOMER_PART_NUMBER = gql`
    mutation RemovePartNumber ($customerPartNumber: String!, $invMastUid: Int!) {
        removeCustomerPartNumber(
            customerPartNumber: $customerPartNumber,
            invMastUid: $invMastUid
        )  
    }
`