import * as Yup from 'yup'

export const existingCustomerSchema = Yup.object().shape({
    firstName: Yup.string()
        .max(15, 'Maximum length of 15')
        .required('required'),
    lastName: Yup.string()
        .max(24, 'Maximum length of 24')
        .required('required'),
    email: Yup.string()
        .max(255, 'Maximum length of 255')
        .email('Invalid email address')
        .required('required'),
    phone: Yup.string()
        .transform((val) => val.replace(/[-]/g,''))
        .min(10, 'Must be exactly 10 numbers')
        .max(10, 'Must be exactly 10 numbers')
        .required('required'),
    phoneExtension: Yup.string()
        .max(5, 'Maximum length of 5'),
    fax: Yup.string()
        .max(20, 'Maximum length of 20'),
    jobTitle: Yup.string()
        .max(40, 'Maximum length of 40'),
    customerId: Yup.number()
        .typeError('Must be a number')
        .integer('Must be a number')
        .required('required, can be provided by a sales representative'),
    password: Yup.string()
        .required('required')
        .min(8, 'Minimum length of 8')
        .max(1000),
    verifyPassword: Yup.string()
        .required('required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    passwordStrength: Yup.boolean()
        .equals([true], 'Check the password complexity requirements.'),

})

export const existingCustomerInitialValues = { 
    firstName: '', 
    lastName: '', 
    email: '', 
    phone: '',
    phoneExtension: '',
    fax: '',
    jobTitle: '',
    customerId: '', 
    password: '', 
    verifyPassword: '',
    passwordStrength: false,
}

export const editCustomerSchema =  Yup.object().shape({
    firstName: Yup.string()
        .max(15, 'Maximum length of 15')
        .required('required'),
    lastName: Yup.string()
        .max(24, 'Maximum length of 24')
        .required('required'),
    jobTitle: Yup.string()
        .max(40, 'Maximum length of 40'),
    phone: Yup.string()
        .transform((val) => val.replace(/[-]/g,''))
        .min(10, 'Must be exactly 10 numbers')
        .max(10, 'Must be exactly 10 numbers')
        .required('required'),
    phoneExtension: Yup.string()
        .max(5, 'Maximum length of 5'),
    email: Yup.string()
        .max(255, 'Maximum length of 255')
        .email('Invalid email address')
        .required('required'),
    fax: Yup.string()
        .max(20, 'Maximum length of 20'),
    shippingCompany: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(50),
    shippingAddress1: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(50),
    shippingAddress2: Yup.string()
        .max(50),
    shippingCity: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(50),
    shippingState: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(50),
    shippingPostal: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(10),
    shippingCountry: Yup.string()
        .when('customerId', { is: '', then: Yup.string().required('required'), otherwise: Yup.string().notRequired() })
        .max(50),
    billingSame: Yup.boolean(),
    billingCompany: Yup.string()
        .max(50)
        .when(['billingSame', 'customerId'], 
            { is: (billingSame, customerId) => billingSame || customerId !== '', then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingAddress1: Yup.string()
        .max(50),
    billingAddress2: Yup.string()
        .max(50),
    billingCity: Yup.string()
        .max(50),
    billingState: Yup.string()
        .max(50),
    billingPostal: Yup.string()
        .max(10),
    billingCountry: Yup.string()
        .max(50),
    customerId: Yup.number()
        .typeError('Must be a number')
        .integer('Must be a number')
})

export const newCustomerSchema = Yup.object().shape({
    firstName: Yup.string()
        .max(15, 'Maximum length of 15')
        .required('required'),
    lastName: Yup.string()
        .max(24, 'Maximum length of 24')
        .required('required'),
    jobTitle: Yup.string()
        .max(40, 'Maximum length of 40'),
    phone: Yup.string()
        .transform((val) => val.replace(/[-]/g,''))
        .min(10, 'Must be exactly 10 numbers')
        .max(10, 'Must be exactly 10 numbers')
        .required('required'),
    phoneExtension: Yup.string()
        .max(5, 'Maximum length of 5'),
    email: Yup.string()
        .max(255, 'Maximum length of 255')
        .email('Invalid email address')
        .required('required'),
    fax: Yup.string()
        .max(20, 'Maximum length of 20'),
    password: Yup.string()
        .required('required')
        .min(8, 'Minimum length of 8')
        .max(1000),
    verifyPassword: Yup.string()
        .required('required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    shippingCompany: Yup.string()
        .required('required')
        .max(50),
    shippingAddress1: Yup.string()
        .max(50)
        .required('required'),
    shippingAddress2: Yup.string()
        .max(50),
    shippingCity: Yup.string()
        .max(50)
        .required('required'),
    shippingState: Yup.string()
        .max(50)
        .required('required'),
    shippingPostal: Yup.string()
        .max(10)
        .required('required'),
    shippingCountry: Yup.string()
        .max(50)
        .required('required'),
    billingSame: Yup.boolean(),
    billingCompany: Yup.string()
        .max(50)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingAddress1: Yup.string()
        .max(50)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingAddress2: Yup.string()
        .max(50),
    billingCity: Yup.string()
        .max(50)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingState: Yup.string()
        .max(50)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingPostal: Yup.string()
        .max(10)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    billingCountry: Yup.string()
        .max(50)
        .when('billingSame', { is: true, then: Yup.string().notRequired(), otherwise: Yup.string().required('required') }),
    passwordStrength: Yup.boolean()
        .equals([true], 'Check the password complexity requirements.'),
});

export const justPasswordValidateSchema = Yup.object().shape({
    password: Yup.string()
        .required('required')
        .min(8, 'Minimum length of 8')
        .max(1000),
    verifyPassword: Yup.string()
        .required('required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    passwordStrength: Yup.boolean()
        .equals([true], 'Check the password complexity requirements.')
});

export const newCustomerInitialValues = {
    id: '',
    customerId: '',
    customerSearch: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phone: '',
    phoneExtension: '',
    email: '',
    fax: '',
    password: '',
    verifyPassword: '',
    shippingCompany: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingState: '',
    shippingPostal: '',
    shippingCountry: '',
    billingSame: false,
    billingCompany: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingState: '',
    billingPostal: '',
    billingCountry: '',
    passwordStrength: false,
}