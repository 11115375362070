import React from 'react'
import styled from "styled-components"
import { ButtonRed, ButtonBlack } from 'styles/buttons'

//Enables the page to show the summary box with a "sticky" side bar for wider screens,
// or flow the summary box to the end of checkout for narrower screens
export const StickyCheckoutContainer = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
`
export const AlertSpan = styled.div`
    margin: 0 0 0 auto;
`

export const Area = styled.div`
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid black;
    border-collapse: collapse;
    margin: 5px;
    padding: 10px;
    min-width: 400px;
    //flex: 1 1 0px;
`

export const StickyArea = styled(Area)`
    position: sticky;
	top: 0;
`

export const StyledCheckbox = styled.input`
	width: 15px;
	height: 15px;
	cursor: pointer;
    margin: 5px;
`

export const AreaHeading = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
    h1:first-of-type {
        font-size: 1.5rem;
    }
    button:first-of-type {
        display: inline-block;
        margin: 0 10px;
    }    
    svg {
        align-self: center;
    }
`

export const AreaContentRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
    // margin-right: 10px;
    // margin-left: 10px;
`

export const Bold = styled.span`
    font-weight: bold;
`

export const Box = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    border: 1px solid black;
    border-radius: 3px;
    border-left: 5px solid ${props => props.theme.altButtonColor};
    background: linear-gradient(to top left, ${props => props.theme.divisionColor}, ${props => props.theme.divisionColorBlendLighter});
    width: 100%;
`

export const BoxContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    word-wrap: anywhere;
`

export const ConfigColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    width: calc(100vw - 500px);
    max-width: 500px;
    @media (max-width: 1000px) {
        width: 90vw;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    h4 {
        font-family: ${props => props.theme.fancyFontNameBold};
    }
    p {
        font-family: ${props => props.theme.fancyFontName};
        text-align: center;
    }
    button {
        margin-top: 8px;
    }
`

export const DivRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
`

export const DivScroll = styled.div`
    border: 1px solid #007bff;
    padding: 5px;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 5px;
`

export const EyeSpace = styled.div`
    width: 22px;
`

export const FlexChild = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
`

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
`

export const FlexColBordered = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px auto;
`

export const FlexInline = styled.div`
    display: flex;
    gap: 5px;
    align-items: flex-start;
`

export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
`

export const FlexRowMargin = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin: 10px;
`

export const FlexTableRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`
export const FlexTableData = styled.div`
    width: 50%;
`
export const FlexTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
export const FullFlexCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const FullWidth = styled.div`
    width: 100%;
`

export const Grower = styled.div`
    flex-grow: 1;
`

const HeaderActionButtonInner = styled.button`
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    height: 25px;
    padding: 0px 15px 3px;
    background-image: ${props => props.disabled ? 'linear-gradient(to top left, Gray, LightGray)' :
    `linear-gradient(to top left, ${props.theme.mainColorBlend}, ${props.theme.mainColor})`};
    border-radius: 30px;
    color: whitesmoke;
    disabled: ${props => props.disabled};
`
export const HeaderActionButton = (props) => <Grower style={{textAlign:"right"}}>
    <HeaderActionButtonInner {...props} />
</Grower>

export const HorizontalPack = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: space-between;
    * {
        margin-right: 5px;
        margin-left: 5px;
    }
`

export const HorizontalPackShip = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const HorizontalPackCenter = styled(HorizontalPack)`
    margin: 0 auto;
`

export const InlineButtonRed = styled(ButtonRed)`
    height: 2.5rem;
`
export const InlineButtonBlack = styled(ButtonBlack)`
    height: 2.5rem;
`

export const Label = styled.label`
    margin: 0;
    font-size: 12px;
    font-style: italic;
    width: 100px;
`

export const Layout = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 10px;
`

export const LayoutArea = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
`

export const Linky = styled.div`
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    font-style: italic;
    color: ${props => props.theme.linkColor};
    :hover {
        text-decoration: underline;
    }
`

export const MainLayout = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 1400px;
    margin: auto;
`

export const MajorColumn = styled.div`
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
`

export const MarginRight = styled.div`
    margin-right: 5px;
`

export const OptionTable = styled.table`
    width: auto;
    margin: 0;
    cursor: pointer;
    tbody > tr > td {
        padding: 15px;
    }
    tbody > tr:nth-child(even) {
        background-color: ${props => props.theme.divisionColorBlendLighter};
    }
    div:first-child {
        border: 0;
    }
`

export const OTable = styled.table`
    width: 100%;
    border: 0px solid ${props => props.theme.divisionColor };
    tbody {
        tr {
            td:first-child 
            {
                font-weight: 500;
            }
            td, th {
                padding-top: 10px;
                padding-bottom: 5px;
                padding-left: 10px;
            }
        }
    }
    tbody.shipGroups {
        tr:nth-child(even) {
            background-color: ${props => props.theme.divisionColorBlendLighter}
        }
    }
    td.shipOptions {
        width: 50%;
    }
    td.itemDesc {
        width: 100%;
    }
    td.image {
        width: 110px;
		vertical-align:top;
    }
    td.subTotal {
        text-align: right;
        vertical-align: top;
    }
    span.itemId {
        padding:2px;
        font-size: 1.2em;                
    }
    span {
        padding:2px;
    }
	small {
		font-size: .75rem;
		font-style: italic;
	}
    span.lineTotal {
        font-size: 1.5rem;
        margin: 10px;
    }
    img.itemImage {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    h5 {
        border-top: 1px solid ${props => props.theme.divisionColor };
        padding-top: 3px;
        margin: 4px
    }
`

export const Pinfo = styled.p`
	font-size: 16px;
	padding: 8px;
	background-color: #e7f2ff;
	border: 1px solid #007bff;
	border-radius: 2px;
	color: #007bff;
    max-width: 500px;
    margin: 0 auto;
`

export const RedText = styled.div`
    color: red;
`

export const Right = styled.div`
    text-align: right;
`

export const SchedTable = styled.table`
    width: 100%;
`

export const ScrollContainer = styled.div`
    max-height: 500px;
    overflow-y: scroll;
    padding: 10px;
    width: 100%;
`

export const SelectedBorder = styled.div`
    border: ${props => props.selected ? '1px solid red' : '1px solid grey'}; 
    padding: 10px; 
    cursor: pointer;
    margin: 10px 0px;
`

export const STable = styled.table`
    tbody {
        tr {
            td {
                padding: 5px;
            }
        }
    }
`

export const SummaryColumn = styled(MajorColumn)`
    margin-right: 15px;
    position: sticky;
    top: 138px;
    align-self: flex-start;
`

export const SumTable = styled.table`
    width: 100%;
    margin-left: 10px;
    tbody {
        tr {
            td:first-child 
            {
                font-weight: 500;
            }
            td:last-child 
            {
                text-align: right;
            }
            td {
                padding-top: 10px;
                padding-bottom: 5px;
            }
        }
    }
`

export const Textarea = styled.textarea`
    width: 450px;
    max-width: 90%;
`
