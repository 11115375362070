import React from 'react'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import { OptionTable } from '../styledComponents'

export default function PaymentOptions(props) {
    return (<>
        {props.options &&
            <OptionTable>
                <tbody>
                    {props.options.map((opt, index) =>
                        <tr key={`pmt_${index}`} onClick={() => props.setSelectedPaymentOption(opt)}>
                            <td>{opt.Label}</td>
                            <td style={{ width: "40px" }}>{opt.Value === props.selectedPaymentOption ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}</td>
                        </tr>
                    )}
                </tbody>
            </OptionTable>}
        {!props.options &&
            <span style={{ textAlign: "center", fontStyle: "italic", width: "100%" }}>No payment options.</span>
        }
    </>
    )
}