import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import FormikInput from '../../../_common/formik/input_v2'
import Required from '../../../_common/required'
import { HeaderActionButton, Layout } from '../../styledComponents'
import { contactSchema } from '../../util/validationSchema'

export default function ContactInput(props) {
    const { 
        contact, 
        hide, 
        contactUpdatedCallback 
    } = props;

    function save(values) {
        console.log("save contact edit", values);
        contactUpdatedCallback(values);        
        hide();
    }

    const [disabled, setDisabled] = useState(true);

    function handleValidateFields(values) {
        contactSchema
            .isValid(values)
            .then((valid) => setDisabled(!valid));
    }

    // function resetContactInput() {
    //     setNewContact({});
    // }

    return (<Formik
                initialValues={contact}
                enableReinitialize={false}
                validationSchema={contactSchema}
                validate={handleValidateFields}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}
                onSubmit={(values) => save(values)}
            >
                <Form>
                    <FormikInput
                        label={<>Title</>}
						automationPrefix="contact-"
                        name="Title"
                    />
                    <FormikInput
                        label={<>First Name<Required /></>}
						automationPrefix="contact-"
                        name="FirstName"
                    />
                    <FormikInput
                        label={<>Last Name<Required /></>}
						automationPrefix="contact-"
                        name="LastName"
                    />
                    <FormikInput
                        label={<>Phone<Required /></>}
						automationPrefix="contact-"
                        name="Phone"
                    />
                    <FormikInput
                        label={<>Phone ext</>}
						automationPrefix="contact-"
                        name="PhoneExt"
                    />
                    <FormikInput
                        label={<>Email<Required /></>}
						automationPrefix="contact-"
                        name="Email"
                    /> 
                    <Layout>
                        <HeaderActionButton id="contact-SaveBtn" type="submit" disabled={disabled}>Save</HeaderActionButton>
                    </Layout>
                </Form>
            </Formik>
    )
}
