import React, { useState } from 'react'
import styled from 'styled-components'
import { useCheckout2 } from 'setup/CheckoutProviderFields';

const Span = styled.span`
  font-style: italic;
  color: red;
  font-size: 12px;
`

export default function NcnrAcknowledgement() {
    const [checked, setChecked] = useState(false);
    const {
        containNcnrItem,
        setAcknowledgeNcnr,
    } = useCheckout2();


    const handleCheckboxChange = () => {
        setAcknowledgeNcnr(!checked);
        setChecked(!checked);
    };
    return (
        <>
            {containNcnrItem &&
                <div className="checkbox-label">
                    <label>
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={handleCheckboxChange}
                        />
                        <Span> Yes, I understand that my cart contains at least one NCNR item. NCNR items are non-cancelable and non-refundable once ordered.</Span>
                    </label>
                </div>
            }
        </>
    );
}
