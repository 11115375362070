import React from 'react'
import styled from 'styled-components'

export default function MenuVisibility(props) {
    const {
        name,
        setOpenDropDownMenu,    
        children,
    } = props 

      return (
        <>
            <div onMouseEnter={ () => setOpenDropDownMenu({[name]:true})}>{children}</div>
        </>
      )
    }