import { format as dateFormat } from 'date-fns'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'

export const getCsvFormattedData = (data, columns, ignoreCols) => {
	const filterCols = ({ accessor }) => !ignoreCols.includes(accessor)
    const dateFields = [
        'orderDate', 'lastDateOrdered', 'invoiceDate', 'netDueDate', 'termsDueDate', 'dueDate', 'quoteDate'
    ]
    const cashFields = [
        'itemsTotal', 'unitPrice', 'totalPrice', 'total', 'currentPrice', 'amountDue', 'amountPaid', 'total'
    ]
    const dataCopy = JSON.parse(JSON.stringify(data))

    dataCopy.map((elem) => {
        for(const d of dateFields) {
            if(elem[d]) {
                elem[d] = dateFormat(new Date(elem[d]), DATE_FORMAT_DISPLAY)
            }
        }
        for(const c of cashFields) {
            if(elem[c]) {
                elem[c] = Number(elem[c]).toFixed(2)
            }
        }

        return elem
    })
        

	return [
			columns.filter(filterCols).map(({ Header }) => Header),
			...(dataCopy || []).map(d => columns.filter(filterCols).map(({ accessor }) => {
					return d[accessor] === true ? 'X' : d[accessor] === false ? '' : d[accessor]
			}))
	]
}

export const exportToExcel = (data, columns, name, ignoreCols=[], fileName) => {
	import('xlsx').then(XLSX => {
			const excelFormat = getCsvFormattedData(data, columns, ignoreCols)
			const worksheet = XLSX.utils.aoa_to_sheet(excelFormat)
			const workBook = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(workBook, worksheet, name)
			XLSX.writeFile(workBook, fileName || `${name}.xlsx`)
	})
}

export const getOrderQuotePdf = (orderOrQuoteId, isQuote) => {
    const token = localStorage.getItem('apiToken')
    const refreshToken = localStorage.getItem('refreshToken')

    var data = {
       orderNo: String(orderOrQuoteId)
    }

    //Attach the JWT tokens to every request.
    if (refreshToken && token) {
        fetch(`${process.env.REACT_APP_API_URL}/api/ReportFiles/OrderQuotePDF`, {
            method: "POST",
            headers: {
                authorization: token ? `Bearer ${token}` : null,
                refreshToken: token ? `RefreshToken ${refreshToken}` : null,
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(response => {
            const link = document.createElement('a')
            link.href = response.headers.get('Location')
            link.download = isQuote ? `airline_quote_${orderOrQuoteId}.pdf` : `airline_order_${orderOrQuoteId}.pdf`
            link.target = '_blank'
            link.rel = 'noopener noreferrer'
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
    }
}

export const getInvoicePdf = (invoiceId) => {
    const token = localStorage.getItem('apiToken')
    const refreshToken = localStorage.getItem('refreshToken')

    var data = {
       invoiceNo: String(invoiceId)
    }

    //Attach the JWT tokens to every request.
    if (refreshToken && token) {
        fetch(`${process.env.REACT_APP_API_URL}/api/ReportFiles/InvoicePDF`, {
            method: "POST",
            headers: {
                authorization: token ? `Bearer ${token}` : null,
                refreshToken: token ? `RefreshToken ${refreshToken}` : null,
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(response => {
            const link = document.createElement('a')
            link.href = response.headers.get('Location')
            link.download = `airline_invoice_${invoiceId}.pdf`
            link.target = '_blank'
            link.rel = 'noopener noreferrer'
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
    }
}