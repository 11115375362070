import React, { useContext } from 'react'
import styled from 'styled-components'
import { Box, BoxContainer } from '../../styledComponents'
import MyContext from 'setup/context';

const SavedContactDiv = styled.div`
	margin-left: 8px;
`

export default function ContactBox(props) {
    const { selectedContact } = props || {};
    const { Title, FirstName, LastName, Phone, PhoneExt, Email, P21Id } = selectedContact;
    const { userInfo } = useContext(MyContext);
    const changeContactLink = `${process.env.REACT_APP_WEB_CONNECT_URL}/Common/Customers/ContactDetails.aspx?ContactID=${P21Id}`
    return (
        <div>
            {P21Id !== null && <Box>
                <BoxContainer>
                    <div>{Title}</div>
                    <div>{`${FirstName} ${LastName}`}</div>
                    <div>{`${Phone} ${PhoneExt ? `Ext. ${PhoneExt}` : ''}`}</div>
                    <div>{Email}</div>
                </BoxContainer>
            </Box>}
            {!!(userInfo?.isImpersonatorUser) && (
                <SavedContactDiv>
                    <div>
                        <a href={changeContactLink} target="_blank" rel="noopener noreferrer">Edit in WebConnect</a>
                    </div>
                </SavedContactDiv>
            )}
        </div>
    )
}