import React, { useEffect, useState } from 'react'
import Modal from '../modal'
import Table from '../table'
import styled from 'styled-components'
import { GET_ASSEMBLY_DETAILS } from 'setup/providerGQL'
import { useQuery } from '@apollo/client'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const HeaderLine = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  > h6 {
    width: 150px;
  }
`

export default function AssemblyModal(props) {
  const { showModal, setShowModal, invMastUid, itemCode } = props
  const [editableData, setEditableData] = useState([])
  const { data, loading } = useQuery(GET_ASSEMBLY_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      invMastUid
    },
  })

  useEffect(() => {
    let set = data
    if (set && set.assemblyInformation && set.assemblyInformation[0].available === undefined) {
      set.assemblyInformation.map((item) => {
        console.log(item)
        item.available = item.componentQtyOnHand - item.componentQtyAllocated
        item.dueIn = item.componentQtyOnPo - item.componentQtyReceivedOnPo
      })
    }
    setEditableData(set)
  }, [data]) 

  const columns = [
    {
      Header: 'Item ID',
      accessor: 'componentItemId',
      width: 250
    },
    {
      Header: 'Quantity',
      accessor: 'componentQuantity',
      width: 140
    },
    {
      Header: 'UOM',
      accessor: 'componentUnitOfMeasure',
      width: 140
    },
    {
      Header: 'Available',
      accessor: 'available',
      width: 140
    },
    {
      Header: 'On PO',
      accessor: 'dueIn',
      width: 145,
      Cell: props => (
        <a
          target='_blank'
          rel='noreferrer'
          href={`${process.env.REACT_APP_WEB_CONNECT_URL}/Common/Inventory/POListing.aspx?InvMastUid=${props.row.original.componentInvMastUid}`}
        >
          {props.value}
        </a>
      )
    },
  ]

  const headerData = data?.assemblyParentInformation[0] || {}
  const { supplierId, supplierName, prefLocation } = headerData

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      contentStyle={{ width: '850px', maxWidth: '90vw', borderRadius: '3px', marginTop: 115, top: -50 }}
    >
      <Header>
        <h4>Components</h4>
        <h6>{itemCode}</h6>
        <HeaderContainer>
          <HeaderLine>
            <h6>Primary Supplier #</h6>
            <div>{supplierId}</div>
          </HeaderLine>
          <HeaderLine>
            <h6>Primary Supplier</h6>
            <div>{supplierName}</div>
          </HeaderLine>
          <HeaderLine>
            <h6>Preferred Location</h6>
            <div>{prefLocation}</div>
          </HeaderLine>
        </HeaderContainer>
      </Header>
      <Table {...{
        columns,
        data: data?.assemblyInformation || [],
        loading,
        dense: true,
        stickyHeader: true
      }} />
    </Modal>
  )
}