import React from 'react'
import styled from 'styled-components'

export const DivOrderInfoContainer = styled.div`
    display: flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  `

export const DivOrderInfo = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column; 
    margin: 8px 0;
    p {
      margin: 0;
      margin-left: 8px;  
    }
`

export const DivHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 4px 0;
    h4 {
      margin: 0;
    }
    p {
      color: grey;
      font-size: 14px;
      margin: 0 0 0 auto;
    }
  `

export const ButtonSmall = styled.button`
    background-color: #b51029;
    color: white;
    font-weight: 600;
    border: 0;
    padding: 4px 8px;
    box-shadow: 1px 1px 2px #000;
    margin: 4px 0px 4px 16px;
    &:hover{
      background-color: rgb(219, 22, 51);
    }
    &:active{
      background-color: #b51029;
      box-shadow: 0px 0px 1px #000;
    }
`

export const Container = styled.div`
    margin: 10px 0;
`

//Line Item Details components

export const DetailDivContainer = styled.div`
		display: flex;
		border-top: 2px whitesmoke solid;
		border-bottom: 2px whitesmoke solid;
		padding: 8px 16px;
		margin: 8px 0;
		min-height: 150px;
		background-color: white;
        flex-direction: column;
	`

export const DetailDivCard = styled.div`
		display: flex;
		align-items: center;
		width: 100%;
	`

export const DetailDivCol1 = styled.div`
		display: flex;
		width: 100px;
	`

export const DetailDivCol2 = styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 200px;
		margin-left: 1rem;
		margin-right: 50px;
		p {
			font-size: 16px;
			margin: 0;
		}
	`

export const DetailDivCol3 = styled.div`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-grow: 99;
		p {
			font-size: 16px;
			margin: 0;
		}
	`

export const DetailFullRow = styled.div`
`

export const DetailImgContainer = styled.div`
		margin: 0 4px;
	`

export const DetailP1 = styled.p`
		font-size: 16px;
		font-weight: 600;
	`

export const DetailTextRow = styled.div`
		display: flex;
	`

export const DetailP2 = styled.p`
		color: grey;
		font-size: 12px !important;
		padding: 0 2px;
    `
export const DetailExtendedDescSpan = styled.span`
    color: grey;
    font-size: 12px !important;
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    height: 36px; /* Fallback for non-webkit */
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const DetailButtonSmall = styled.button`
		background-color: #b51029;
		color: white;
		font-weight: 600;
		border: 0;
		padding: 4px 8px;
		box-shadow: 1px 1px 2px #000;
		margin: 4px auto 4px auto;
		&:hover{
			background-color: rgb(219, 22, 51);
		}
		&:active{
			background-color: #b51029;
			box-shadow: 0px 0px 1px #000;
		}
	`
