import React from 'react'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'reactjs-popup/dist/index.css';

const CloseDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;  
  width: auto;
  margin: 5px 20px;
`
//A modal that does not dismiss if the users clicks away from it
export function StickyModal(props) {
    const {
        contentStyle,
        onClose,
        open
    } = props

    /* Note: I removed lockScroll because it causes overflow:auto/hidden to be set on the body element, which disrupts complex layout activities*/
    return (
        <Popup open={!!open} onClose={onClose} closeOnDocumentClick={false} contentStyle={contentStyle}>
            <>
                <CloseDiv><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={onClose} icon='times' size='lg' color='lightgrey' /></CloseDiv>
                {props.children}
            </>
        </Popup>
    )
}

export default function Modal(props) {
    const {
        contentStyle,
        onClose,
        open
    } = props

    return (
        <Popup open={!!open} onClose={onClose} modal={true} closeOnDocumentClick={false} contentStyle={contentStyle}>
            <>
                <CloseDiv><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={onClose} icon='times' size='lg' color='lightgrey' /></CloseDiv>
                {props.children}
            </>
        </Popup>
    )
}
