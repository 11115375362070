import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavigationItemContainerElement = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
        // background-color: #535353;
        border-bottom: 3px solid #DB1633;
        .nav-link {
            // color: white;
            color: #535353;
        }

        .nav-dropdown {
            visibility: visible;
        }
    }

    .nav-link {
        text-decoration: none;
        font-weight: 500;
        font-size: 1rem;
        color: black;
    }
`
const DropdownElement = styled.div`
    z-index: 1;
    position: absolute;
    display: block;
    background-color: #535353;
    visibility: hidden;
    // border-top: 1px solid black;
    max-height: 550px;
    overflow: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to top left,#950f23,#DB1633);
        border-radius: 20px;
    }
    &:hover {
        visibility: visible;
    }

    &.visible {
        visibility: visible;
    }

    .dropdown-link {
        padding: 0.5rem 1rem;
        text-align: center;

        a {
            color: white;
            display: block;
            white-space: nowrap;
        }

        &:hover {
            background-color: #007bff;
            cursor: pointer;
        }
    }
`

const DropdownElementRight = styled(DropdownElement)`
    right: 0px
`

const DropdownLink = styled.div`
    padding: 0.5rem 1rem;
    text-align: center;
    background-color: #535353;
    a, div {
        color: white;
        display: block;
        white-space: nowrap;
    }
    &:hover {
        background-color: #007bff;
        cursor: pointer;
    }
`

const ImgDropdownElement = styled.div`
    z-index: 1;
    position: absolute;
    display: block;
    background-color: #535353;
    visibility: hidden;
    max-height: 550px;
    background: transparent;
    border-top: 0;
    padding: 5px 5px;
    
    &:hover {
        visibility: visible;
    }

    &.visible {
        visibility: visible;
    }

    .dropdown-link {
        padding: 0.5rem 1rem;
        text-align: center;

        a {
            color: white;
            display: block;
            white-space: nowrap;
        }

        &:hover {
            background-color: #007bff;
            cursor: pointer;
        }
    }
`
const TransparentBackground = styled.div`
    background-color: white;
`
const ShopImgDropdownElement = styled.div`
    z-index: 1;
    position: absolute;
    background: transparent;
    border-top: 0;
    padding: 5px 5px;
    visibility: hidden;
    max-height: 550px;
    display: flex;
    flex-wrap: wrap;
    max-width:1200px;
    width: 80vw;

    &:hover {
        visibility: visible;
    }

    &.visible {
        visibility: visible;
    }

    .dropdown-link {
        padding: 0.5rem 1rem;
        text-align: center;

        a {
            color: white;
            display: block;
            white-space: nowrap;
        }

        &:hover {
            background-color: #007bff;
            cursor: pointer;
        }
    }
`

export const NavigationItemContainer = React.forwardRef(({ children, to, text }, ref) => (
    <NavigationItemContainerElement ref={ref}>
        <Link to={to} className="nav-link">{text}
            {!!children?.props?.children?.length && (
                <span style={{ position: 'absolute', right: '0.25rem' }}>
                    <FontAwesomeIcon icon='caret-down' color="black" />
                </span>
            )}
        </Link>
        {children}
    </NavigationItemContainerElement>
))

export const MyAccountDropdownMenu = ({ children, className }) => (
    <DropdownElementRight className={'nav-dropdown ' + className}>
        {children}
    </DropdownElementRight>
)

export const CartsDropdownMenu =  ({ children, className, style }) => (
    <DropdownElement className={'nav-dropdown ' + className} style={{ top: '2rem', ...style }} >
        {children}
    </DropdownElement>
)

export const DropdownMenu = ({ children, className }) => (
    <DropdownElement className={'nav-dropdown ' + className} style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', marginTop: '-30px'}}>
        {children}
    </DropdownElement>
)
export const ImgDropdownMenu = ({ children, className }) => (
    <ImgDropdownElement className={'nav-dropdown ' + className} style={{ top: '109%', transform: 'translateX(-40%)', background:'transparent'}}>
            <TransparentBackground>
            {children}
            </TransparentBackground>
    </ImgDropdownElement>
)
export const BrandDropdownMenu = ({ children, className }) => (
    <ImgDropdownElement className={'nav-dropdown ' + className} style={{ top: '109%', transform: 'translateX(-31%)', background:'transparent'}}>
            <TransparentBackground>
            {children}
            </TransparentBackground>
    </ImgDropdownElement>
)
export const ShopImgDropdownMenu = ({ children, className }) => (
   
        <ShopImgDropdownElement className={'nav-dropdown ' + className} style={{ top: '109%', background:'transparent'}}>
            <TransparentBackground>
            {children}
            </TransparentBackground>
        </ShopImgDropdownElement>
    
)


export const DropDownMenuAction = React.forwardRef(({ linkText, onClick }, ref) => (
    <DropdownLink ref={ref}>
        <div onClick={onClick}>{linkText}</div>
    </DropdownLink>
))

export const DropdownMenuItem = React.forwardRef(({ children: linkText, to, isExternalLink }, ref) => (
    <DropdownLink ref={ref}>
        {
            (isExternalLink)
                ? <a href={to} target='_blank' rel='noreferrer'>{linkText}</a>
                : <Link to={to}>{linkText}</Link>
        }
    </DropdownLink>
))
